import React,{useState,useEffect} from 'react';

import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Table from '@mui/joy/Table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Grid,Card,CardContent, Input,Button,Checkbox, Typography,Alert,Modal,ModalDialog,ModalClose,CardActions } from '@mui/joy';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Pagination from 'rc-pagination';

import { Link, useParams,useSearchParams } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Search as SearchIcon, Add as AddIcon,DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import DecisionUpdate from './DecisionUpdate';
import DecisionCreate from './DecisionCreate';




const getAreaList = (input) => {

    return axios.get(`/admin/decision/area/search?q=${input}`).then((response) => {
        let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
        return options;
    });

}


const getCompanyList = (input) => {
 
    return axios.get(`/admin/decision/company/search?q=${input}`).then((response) => {
        let options = response.data.data.map((item) => ({ value:item.id, label: item.name }));
        return options;
    });

}

const getTechnologyList = (input) => {
 
    return axios.get(`/admin/decision/technology/search?q=${input}`).then((response) => {
        let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
        return options;
    });

}


const DecisionAdm = () => {

    const [dataList,setDataList] = useState([]);
    let defaultPagination = {
        current_page:1,
        per_page:0,
        total:0,
    }

    const [pagination,setPagination] = useState(defaultPagination);
    const [interval,setInterval] = useState(false);
    const [frequencyInterval,setFrequencyInterval] = useState(false);

    const [decisionType,setDecisionType] = useState([]);

    let defaultSearchForm = {

        number:"",
        date:"",
        startDate:"",
        endDate:"",
        deadline:"",
        startDeadline:"",
        endDeadline:"",
        frequency:"",
        frequencyStart:"",
        frequencyEnd:"",
        type:"",
        company:"",
        importer:"",
        developer:"",
        vendor:"",
        area:"",
        technology:"",
        page:1,
        measure:"",

    }

    const [searchForm,setSearchForm] = useState(defaultSearchForm)

    const [searchParams,setSearchParams] = useSearchParams();

    const [selectAll,setSelectAll] = useState(false);
    let [selected,setSelected] = useState([]);

    const { t } = useTranslation();
    const [reload,setReload] = useState(false);


    const defaultActiveRecord = {
        id:null,
    }

    const [activeRecord,setActiveRecord] = useState(defaultActiveRecord);
    const [openModal,setOpenModal] = useState(false);
    
   useEffect(() => {

        axios.get(`/admin/decision/decision_type_list`).then((response) => {
          //  let options = response.data.map((item) => ({ value:item.id, label: item.title }));
            setDecisionType(response.data);
        });
        if(isSearchPage()){

            if(searchParams.get('page')){
               
                loadSearch(searchParams.get('page'));
   
               }else{
           
                loadSearch();
   
               }
            
           
        }else{

            if(searchParams.get('page')){
             ///   alert('default with page');
                loadPage(searchParams.get('page'))
                setSearchForm(defaultSearchForm)

            }else{
                
              // alert('default without page');
                loadPage();
                setSearchForm(defaultSearchForm)

            }

        }

       

    },[searchParams.get('page'),reload]);



   


    useEffect(()=>{
        
        if(frequencyInterval){
            setSearchForm({...searchForm,frequency:""});
        }

        if(!frequencyInterval){
            setSearchForm({...searchForm,frequencyStart:"",frequencyEnd:""})
        }
        
    },[frequencyInterval])

 
    const searchHandle = () => {

        setSearchParams({...searchForm});
        loadSearch();

    }

    const clearHandle = () => {

        setSearchForm(defaultSearchForm);
        setSearchParams({pageType:'default'});
        window.location.reload();
       
    
    }

    const setDate = (e,type="date") => {
        if(e){
            let c_day = e.$D;
            let c_month = e.$M+1;
            let c_year = e.$y;
            if(type=='date'){
                setSearchForm({...searchForm,date:c_day+'-'+c_month+'-'+c_year});
            }else if(type=='deadline'){
                setSearchForm({...searchForm,deadline:c_day+'-'+c_month+'-'+c_year});
            }else if(type=='startDate'){
                setSearchForm({...searchForm,startDate:c_day+'-'+c_month+'-'+c_year});
            }else if(type=='endDate'){
                setSearchForm({...searchForm,endDate:c_day+'-'+c_month+'-'+c_year});
            }else if(type=='startDeadline'){
                setSearchForm({...searchForm,startDeadline:c_day+'-'+c_month+'-'+c_year});
            }
            else if(type=='endDeadline'){
                setSearchForm({...searchForm,endDeadline:c_day+'-'+c_month+'-'+c_year});
            }else{

            }
               
        }else{
            setSearchForm({...searchForm,date:''});
        }
    }

 

   const changePage = (page) => {
    setPagination({current_page:page});

    if(isSearchPage()){
        
        setSearchParams({...searchForm,page:page,pageType:'search'});
        loadSearch(page);
        console.log('SEARCH PAGE');
    }else{

        setSearchParams({page:page,pageType:'default'});
        loadPage(page);
        console.log('DEFAULT PAGE');


    }    

    
    
   }

   function loadPage(page=1){

        
        axios.get('/admin/decision/index?&pageType=default&page='+page).then((response) => {

            setDataList(response.data.data);
          
          
            setPagination({
                current_page:response.data.current_page,
                per_page:response.data.per_page,
                total:response.data.total,
            })

      }); 

   }
  
   function loadSearch(page=1)
   {
        
        setSearchForm({...searchForm});

        let params = `?page=${page}&number=${searchForm.number}
        &date=${searchForm.date}&deadline=${searchForm.deadline}
        &frequency=${searchForm.frequency}&type=${searchForm.type}
        &company=${searchForm.company}&area=${searchForm.area}
        &frequencyStart=${searchForm.frequencyStart}
        &importer=${searchForm.importer}&developer=${searchForm.developer}
        &startDate=${searchForm.startDate}&endDate=${searchForm.endDate}
        &startDeadline=${searchForm.startDeadline}&endDeadline=${searchForm.endDeadline}
        &frequencyEnd=${searchForm.frequencyEnd}&measure=${searchForm.measure}&pageType=search`;


        if(searchForm.technology && searchForm.technology.length)
        {
            let tech = searchForm.technology.map((item)=>item.value);
            params = params+`&technology=${tech}`;
        }
    

        axios.get('/admin/decision/full-search'+params).then((response) => {
           
            
            setDataList(response.data.data);
            
            setPagination({
                current_page:response.data.current_page,
                per_page:response.data.per_page,
                total:response.data.total,
            })
        })

    }

    function isSearchPage()
    {
        let v = searchParams.get('pageType');
        if(v=='default'){
            return false;
        }else{
            return true;
        }

    }


    const editSingleDecision = (item) => {
        if(item.type && item.type.id){
             item.type = {
                 label:item.type.title,
                 value:item.type.id,
             };
        }
 
        if(item.service && item.service.length){
             item.service = item.service.map((service)=>{
                 return {
                     label:service.name,
                     value:service.id,
                 }
             })
        }
        setActiveRecord(item);
        setOpenModal(true);
     }


  

     const removeRecord = (id) => {
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to revert this")+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it")+"!",
          }).then((result) => {
            if (result.isConfirmed) {

                axios.delete('admin/decision/remove/'+id).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success",
                                timer:1500,
                            });
                        }
                });

            }
          });
    
        }


     const setRecordList = (item,action,all=false) => {
         
        let selectedData = [];
        if(all){
            selectedData =  dataList.map((el,index) => {
                el.isChecked = action;
                if(action){
                    if(!selected.includes(el.id)){
                        selected.push(el.id)
                    }
                }else{
                    selected.map((iden,index)=>{
                        if(iden==el.id){
                            selected.splice(index,1);
                        }
                    })
                }
                return el;
            });
        }else{
            selectedData =  dataList.map((el)=>{
                if(el.id==item.id){
                    el.isChecked = action;
                    if(action){
                        selected.push(el.id);
                    }else{
                        setSelectAll(false);
                        selected.map((iden,index) => {
                            if(iden==el.id){
                                selected.splice(index,1);
                            }
                        })

                    }
                }
                return el;
            });
        }
        
        setDataList(selectedData);
    
}

const createDecision = () => {
    setActiveRecord(defaultActiveRecord);
    setOpenModal(true);
}


const removeSelected = () => {
    
    Swal.fire({
        title: t("Are you sure")+"?",
        text: t("You won't be able to revert this")+"!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it")+"!",
    }).then((result) => {

        if(result.isConfirmed){
            
            axios.post('admin/decision/remove-selected/',{payload:selected}).then((res) => {
                    if(res.data.message=='success'){
                        setReload(!reload);
                        setSelected([]);
                        setDataList([]);
                        Swal.fire({
                            title: t("Deleted"),
                            text: t("Your object has been deleted"),
                            icon: "success"
                        });
                    }
            });

        }
    });
}


  return (
    <div>
        {/* Modal */}
        <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
              <ModalDialog variant="soft" size="lg">
                  <p>
                      <ModalClose variant="plain" sx={{ m: 1 }} />   
                  </p>
                      <Card style={{minHeight:'300px'}}>

                        <CardContent>
                            {
                                activeRecord.id ? 
                                    
                                     <>
                                     <DecisionUpdate 
                                       
                                       defaultRecord={activeRecord}
                                       setOpenModal = {setOpenModal}
                                       reload={reload}
                                       setReload={setReload}  
                                       />

                                     </>   
                                :   
                                    <DecisionCreate 
                                        setOpenModal = {setOpenModal}
                                        defaultRecord = {activeRecord}
                                        reload={reload}
                                        setReload={setReload}  
                                     />
                            }


                        </CardContent>
                        <CardActions>
                                                          
                            

                        </CardActions>        
                      </Card>
              </ModalDialog>
          </Modal>
        {/* Modal */}

        <Card style={{marginBottom:'15px',backgroundColor:'#dde7ee' }} className="search-form-card">
            <CardContent>
                <Grid container spacing={2} sx={{ flexGrow: 1,}}>

                    {
                        (interval) ? 
                        <>
                   

                        
                        <Grid xs={3}>
                            <div>
                                <label>Qaror Raqami</label>
                                <Input  value={searchForm.number} onChange={(e)=>setSearchForm({...searchForm,number:e.target.value})} />
                            </div>
                        </Grid>
                        <Grid xs={2}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <label>Qabul qilingan</label>
                                    <DatePicker className='data-picker' sx={{backgroundColor:'#fff'}} onChange={ (e) => setDate(e) } labelId="demo-select-small-label2"  slotProps={{textField:{size:"small"},actionBar:{actions:['clear']}}}/>
                                </LocalizationProvider>
                            </div>
                        </Grid>

                        <Grid xs={2}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <label>Oraliq</label>
                                    <DatePicker  className='data-picker' sx={{backgroundColor:'#fff'}} onChange={ (e) => setDate(e,'endDate') } labelId="demo-select-small-label2"  slotProps={{textField:{size:"small"},actionBar:{actions:['clear']}}}/>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid xs={2}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <label>Muddat</label>
                                    <DatePicker  className='data-picker' sx={{backgroundColor:'#fff'}}  onChange={ (e) => setDate(e,'startDeadline') } labelId="demo-select-small-label2"  slotProps={{textField:{size:"small"},actionBar:{actions:['clear']}}}/>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                        <Grid xs={2}>
                            <div>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <label>Oraliq</label>
                                    <DatePicker  className='data-picker'  sx={{backgroundColor:'#fff'}} onChange={ (e) => setDate(e,'endDeadline') } labelId="demo-select-small-label2"  slotProps={{textField:{size:"small"},actionBar:{actions:['clear']}}}/>
                                </LocalizationProvider>
                            </div>
                        </Grid>
                    
                        </>
                        :
                            <>
                           
                            <Grid xs={3}>
                                    <div>
                                        <label>Qaror Raqami</label>
                                        <Input  value={searchForm.number} onChange={(e)=>setSearchForm({...searchForm,number:e.target.value})} />
                                    </div>
                                </Grid>
                                <Grid xs={3}>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <label>Qabul qilingan</label>
                                            <DatePicker className='data-picker' onChange={ (e) =>  setDate(e) } labelId="demo-select-small-label2"   slotProps={{textField:{size:"small"},actionBar:{actions:['clear']},actionBar:{actions:['clear']}}}/>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                                <Grid xs={3}>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <label>Muddat</label>
                                            <DatePicker className='data-picker' onChange={ (e) => setDate(e,'deadline') } labelId="demo-select-small-label2"  slotProps={{textField:{size:"small"},actionBar:{actions:['clear']},actionBar:{actions:['clear']}}}/>
                                        </LocalizationProvider>
                                    </div>
                                </Grid>
                               
                            </>
                    }

                    {
                        frequencyInterval ?
                            <>
                            <Grid xs={2}>
                                <div>
                                    <label>Chastota</label>
                                    <Input placeholder="" onChange={(e)=>setSearchForm({...searchForm,frequencyStart:e.target.value})}/>
                                </div>
                            </Grid>
                            <Grid xs={2}>
                                <div>
                                    <label>Oraliq</label>
                                    <Input placeholder="" onChange={(e)=>setSearchForm({...searchForm,frequencyEnd:e.target.value})}/>
                                </div>
                            </Grid>
                            </> 
                        :<Grid xs={2}>
                            <div>
                                <label>Chastota</label>
                                <Input placeholder="" onChange={(e)=>setSearchForm({...searchForm,frequency:e.target.value})}/>
                            </div>
                        </Grid>
                    }
                    <Grid xs={1}>
                        <div>
                                <label>Birlik</label>
                                <Select          
                                    labelId="demo-select-small-label1321"
                                    id="demo-select-small-label12132"
                                    onChange={ (e,v) => { setSearchForm({...searchForm,measure:v}) } } 
                                >
                                          <Option value="khz">KHz</Option>
                                          <Option value="mhz">MHz</Option>
                                          <Option value="ghz">GHz</Option>
                                </Select>
                        </div>
                    </Grid>
                    <Grid xs={3}>
                        <div>   <label>Qaror turi</label>
                                <Select          
                                    labelId="demo-select-small-label12321"
                                    id="demo-select-small-label12321"
                                    onChange={ (e,v) => { setSearchForm({...searchForm,type:v}) } } 
                                >
                                        <Option value="">--</Option>
                                        {
                                            decisionType.length ?
                                                decisionType.map((item)=>{
                                                   return  <Option value={item.id}>{item.title}</Option>
                                                })
                                            :""
                                        }
                                </Select>
                        </div>
                    </Grid>
                    <Grid xs={3}>
                        <div>
                            <label>Foydalanish Texnologiyasi</label>
                            <AsyncSelect  className='async-select' isMulti={true}  loadOptions={getTechnologyList}  onChange={ (v) => { setSearchForm({...searchForm,technology:v}) } }  />
                        </div>
                    </Grid>
                    <Grid xs={3}>
                        <div>
                            <label>Xudud</label>
                            <AsyncSelect className='async-select' onChange={(e)=>{e ? setSearchForm({...searchForm,area:e.value}) : setSearchForm({...searchForm,area:''})}}  loadOptions={getAreaList} />
                        </div>
                    </Grid>
                    <Grid xs={3}>
                        <div>
                            <label>Ishlab chiqaruvchi</label>
                            <AsyncSelect  onChange={(e)=>{ e ? setSearchForm({...searchForm,developer:e.value}) : setSearchForm({...searchForm,developer:''})}} className='async-select'  loadOptions={getCompanyList} />
                        </div>
                    </Grid>
                    <Grid xs={3}>
                        <div>
                            <label>Import qiluvchi</label>
                            <AsyncSelect  onChange={(e)=>{setSearchForm({...searchForm,importer:e.value})}} className='async-select'  loadOptions={getCompanyList} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid xs={12}>
                        <div>
                            <label style={{float:'left',margin:'15px 0'}}>
                                <Checkbox color="primary" onChange={()=>setInterval(!interval)} variant="solid" />
                                <span style={{position:'relative',top:'-4px',left:'4px'}}>Oraliq sana</span>
                            </label>
                            <label style={{float:'left',margin:'15px 0 0 15px'}}>
                                <Checkbox color="primary" onChange={()=>setFrequencyInterval(!frequencyInterval)} variant="solid" />
                                <span style={{position:'relative',top:'-4px',left:'4px'}}>Oraliq chastota</span>
                            </label>
                            
                            <Button style={{float:'right',margin:'15px 0'}} onClick={()=>searchHandle()}>Search</Button>
                         
                            <Button style={{float:'right',margin:'15px 0'}} color='danger' onClick={()=>clearHandle()}>Clear</Button>
                          
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        
      <Table 
        aria-label="table variants"
        variant='outlined'
        stripe="odd"
        hoverRow
        color='neutral'
        stickyHeader
        stickyFooter
        >
        <thead>
          <tr>
            <th style={{width:'20px'}}>#</th>        
            <th>Raqam</th>
            <th>Sana</th>
            <th>Muddat</th>
            <th>Chastota</th>
            <th>Qaror turi</th>
            <th>Texnologiya</th>
            <th>Foydalanuvchi</th>
            <th  style={{textAlign:'right',width:'120px'}}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button onClick={()=>{createDecision()}} variant='solid' color="success"><AddIcon /></Button>
                    </Grid>
                    <Grid item>
                        {   
                        selected.length ?
                        <Button onClick={()=>removeSelected()} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                        :""
                        }
                    </Grid>
                </Grid>   
            </th>
          </tr>
        </thead>
        <tbody>
    
        {
                (dataList && dataList.length) ?
                dataList.map((row) => (
                   
                    <tr key={row.id}>
                        <td><Checkbox checked={row.isChecked ?? false} onChange={(e)=>setRecordList(row,e.target.checked)}  className='checking-area' /></td>
                        <td>{row.number}</td>
                        <td>{row.acceptance_date}</td>
                        <td>{row.deadline}</td>
                        <td>
                            {
                                (row.frequency && row.frequency.length) ? 
                                    row.frequency.map((item) => {
                                        let view = item.frequency_start;
                                        if(item.frequency_end){
                                            view = view + "-" + item.frequency_end;
                                        }
                                        view = view + item.measure;
                                        return <Typography>{view}</Typography>;
                                    })
                                :""
                            }
                        </td>
                        <td>{row.type ? row.type.title : ""}</td>
                        <td>
                            {
                                (row.technology && row.technology.length) ? 
                                    row.technology.map((item)=>{
                                        return <Alert
                                                color="warning"
                                                size="sm"
                                                variant="soft"
                                                style={{display:'inline-block',marginRight:'5px'}}
                                                >{item.title}
                                            </Alert>
                                    })
                                :""
                            }
                
                        </td>
                        <td>
                            {row.user ? 
                                <Typography variant="outlined" color="success">
                                    {row.user.name}
                                </Typography>
                            :""
                            }
                        </td>
                        <td>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Link to={`/admin/decision/related/${row.id}`}>
                                        <Button onClick={()=>{}}><AccountTreeIcon /></Button>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Button onClick={()=>editSingleDecision(row)} variant='solid' color="success"><EditIcon /></Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={()=>removeRecord(row.id)} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                                </Grid>
                            </Grid>
                        </td>
                    </tr>
                ))
                :
                    <tr key={Date.now()}>
                    </tr>
                    
        }
        </tbody>
      </Table>
      <Pagination 
        current={pagination.current_page} 
        total={pagination.total} 
        className='pagination' 
        pageSize={10} 
        onChange={(current)=>{changePage(current)}}
        prevIcon=<KeyboardArrowLeftIcon/>
        nextIcon=<KeyboardArrowRightIcon/>
        jumpPrevIcon=<KeyboardDoubleArrowLeftIcon style={{position:'relative',top:'5px'}}/>
        jumpNextIcon=<KeyboardDoubleArrowRightIcon style={{position:'relative',top:'5px'}}/>
    
      />
    </div>
  );
}


export default DecisionAdm;