import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Input, Button, Checkbox, Typography, Alert, Modal, ModalDialog, ModalClose, FormLabel, Select, Option } from '@mui/joy';
import Table from '@mui/joy/Table';

import { Search as SearchIcon, Add as AddIcon,DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import HttpsIcon from '@mui/icons-material/Https';
import KeyIcon from '@mui/icons-material/Key';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Pagination from 'rc-pagination/lib/Pagination';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import axios from 'axios';


const  Main = () => {

    const [selected,setSelected] = useState([]);
    const { t } = useTranslation();
    const createDecision= ()=>{} 
    const removeSelected= ()=>{} 
    const [dataList,setDataList] = useState([]);
    const [reload,setReload] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const [passwordModal,setPasswordModal] = useState(false);
    const [updateUserModal,setUpdateUserModal] = useState(false);

    const [userModal,setUserModal] = useState(false);
    

    const [newPassword,setNewPassword] = useState({
        userId:'',
        password:'',
        repeatPassword:'',
    });
    
    const [userForm,setUserForm] = useState({
        name:'',
        lastName:'',
        email:'',
        password:'',
        repeatPasswrod:'',
        role:'user',
        status:'active',
    });

    const [userUpdateForm,setUserUpdateForm] = useState({
        name:'',
        last_name:'',
        email:'',
        role:'user',
    });

    const changeUser = (user) => {
        console.log(user);
        setValue('updateUserName',user.name);
        setValue('updateUserLastName',user.last_name);
        setValue('updateUserEmail',user.email);
        setValue('updateUserRole',user.role);
        setUserUpdateForm(user);
        setUpdateUserModal(true);
    }

    const [pagination,setPagination] = useState({
        total:0,
        rowsPerPage:0,
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
      } = useForm();  

    const [searchParams,setSearchParams] = useSearchParams();
    let currentPageNumber  = searchParams.get('page') ? parseInt(searchParams.get('page')):1;
    
    const[pageNumber,setPageNumber] = useState(currentPageNumber);

    useEffect(() => {

        axios.get('/admin/user?page='+pageNumber).then((response) => {
            setDataList(response.data.data);
            setPagination({
                current_page:response.data.current_page,
                per_page:response.data.per_page,
                total:response.data.total,
            })

      }); 
    },[pageNumber,reload]);


    const onChangeUser = () => {
      //  console.log(userUpdateForm);

        axios.post('/admin/user/update',userUpdateForm).then((res) => {
            setUpdateUserModal(false);
            if(res.data.message=='success'){
                
                Swal.fire({
                    title: t("User Changed!"),
                    text: t("User Changed successfully"),
                    icon: "success",
                    timer:1500,
                });
                setReload(!reload);
           
            }else{
                Swal.fire({
                    title: t("Error!"),
                    text: t("User Not  Changed"),
                    icon: "error",
                    timer:1500,
                });
           }
        });


    }

    const onCreateUser = () => {
        axios.post('/admin/user/create',userForm).then((res) => {
            setValue('name','');
            setValue('lastName','');
            setValue('email','');
            setValue('password','');
            setValue('repeatPassword','');
            setUserForm({
                name:'',
                lastName:'',
                email:'',
                password:'',
                repeatPasswrod:'',
                role:'user',
                status:'active',
            });
            setOpenModal(false);
            if(res.data.message=='success'){
                
                Swal.fire({
                    title: t("User Created!"),
                    text: t("User Created successfully"),
                    icon: "success",
                    timer:1500,
                });
                setReload(!reload);
           
            }else{
                Swal.fire({
                    title: t("Error!"),
                    text: t("User Not  Created"),
                    icon: "error",
                    timer:1500,
                });
           }
        });
    }

    const onPasswordChange = () => {
        
        if(newPassword.password && newPassword.password==newPassword.repeatPassword){
            axios.post('/admin/user/change-password',newPassword).then((response)=>{
                if(response.data.message=='success'){
                    setNewPassword({password:'',repeatPassword:'',userId:''});
                    setPasswordModal(false);
                    setValue('newPassword','');
                    setValue('repeatNewPassword','');
                    Swal.fire({
                        title: t("Password Changed!"),
                        text: t("Password Changed successfully"),
                        icon: "success",
                        timer:1500,
                    });
                }
            });
        }

    }


    const blockUser = (user,block=true) => {
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to"+ block ? "block user ":"unlock user " )+" "+user.name+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes," + block ? "block ":"unlock" + "it")+"!",
          }).then((result) => {
            if (result.isConfirmed){
                let link = '/admin/user/block';
                if(!block){
                    link = '/admin/user/unlock'
                }
                axios.post(link,{id:user.id}).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            setSelected([]);
                            Swal.fire({
                                title: t("Blocked"),
                                text: t("Your object has been blocked"),
                                icon: "success"
                            });
                        }
                });

            }
          });
    }

    const deleteUser = (user) => {
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to delete user")+" "+user.name+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it")+"!",
          }).then((result) => {
            if (result.isConfirmed){
                let link = '/admin/user/delete/'+user.id;
                axios.delete(link,{id:user.id}).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            setSelected([]);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success"
                            });
                        }
                });

            }
          });
    }

    const changePage = (page) => {
       // setSelected([]);
        setSearchParams({page:page})
        setPageNumber(page);

    }

    const changePassword = (user) => {
        setNewPassword({...newPassword,userId:user.id});
        setPasswordModal(true);
    }

    return (
    <div>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={passwordModal}
                onClose={() => setPasswordModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >   
                <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                <form
                       
                    >
                                <FormLabel>* Parol {
                                    errors.newPassword && errors.newPassword.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            {       
                                errors.newPassword && errors.newPassword.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input {...register("newPassword", { required: true,maxLength:30, })}  variant="outlined" color="primary" onChange={(ev) => setNewPassword({...newPassword,password:ev.target.value})} />
                            <FormLabel>* Parol Takrorlang {
                                errors.repeatNewPassword && errors.repeatNewPassword.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            {       
                                errors.repeatNewPassword && errors.repeatNewPassword.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("repeatNewPassword", { required: true,maxLength:30, })}  variant="outlined" color="primary" onChange={(ev)=>{setNewPassword({...newPassword,repeatPassword:ev.target.value})}} />    
                        <Button type="submit" onClick={()=>onPasswordChange()}>Submit</Button>
                    </form>
              </ModalDialog>  
            </Modal>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                    <form
                    onSubmit={handleSubmit(onCreateUser)}
                    >
                            <FormLabel>* Ism {
                                
                                errors.name && errors.name.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }

                            {       
                                errors.name && errors.name.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("name", { required: true,maxLength:30, })} value={userForm.name} variant="outlined" color="primary" onChange={(ev)=>setUserForm({...userForm,name:ev.target.value})} />
                           
                            <FormLabel>* Familiya {
                                
                                errors.lastName && errors.lastName.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.lastName && errors.lastName.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("lastName", { required: true,maxLength:30, })} value={userForm.lastName} variant="outlined" color="primary" onChange={(ev)=>setUserForm({...userForm,lastName:ev.target.value})} />
                           

                            <FormLabel>* Email {
                                
                                errors.email && errors.email.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.email && errors.email.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("email", { required: true,maxLength:30, })} value={userForm.email} variant="outlined" color="primary" onChange={(ev)=>setUserForm({...userForm,email:ev.target.value})} />
                           
                            <FormLabel>* Parol {
                                
                                errors.password && errors.password.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.password && errors.password.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input {...register("password", { required: true,maxLength:30, })} value={userForm.password} variant="outlined" color="primary" onChange={(ev)=>setUserForm({...userForm,password:ev.target.value})} />
                           
                            <FormLabel>* Parol Takrorlang {
                                
                                errors.repeatPassword && errors.repeatPassword.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.repeatPassword && errors.repeatPassword.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>

                            <Input {...register("repeatPassword", { required: true,maxLength:30 })} value={userForm.repeatPassword} variant="outlined" color="primary" onChange={(ev)=>setUserForm({...userForm,repeatPassword:ev.target.value})} />
                           
                        <Button type="submit" onClick={() => onCreateUser()}>Submit</Button>
                        
                    </form>
            </ModalDialog>
        </Modal>



         <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={updateUserModal}
                onClose={() => setUpdateUserModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                    <form>
                            <FormLabel>* Ism {
                                
                                errors.updateUserName && errors.updateUserName.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            
                            {       
                                errors.updateUserName && errors.updateUserName.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("updateUserName", { required: true,maxLength:30, })}  variant="outlined" color="primary" onChange={(ev)=>setUserUpdateForm({...userUpdateForm,name:ev.target.value})} />
                           
                            <FormLabel>* Familiya {
                                
                                errors.updateUserLastName && errors.updateUserLastName.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.updateUserLastName && errors.updateUserLastName.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("updateUserLastName", { required: true,maxLength:30, })}  variant="outlined" color="primary" onChange={(ev)=>setUserUpdateForm({...userUpdateForm,last_name:ev.target.value})} />
                           

                            <FormLabel>* Email {
                                
                                errors.updateUserEmail && errors.updateUserEmail.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.updateUserEmail && errors.updateUserEmail.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            
                            <Input  {...register("updateUserEmail", { required: true,maxLength:30, })}  variant="outlined" color="primary" onChange={(ev)=>setUserUpdateForm({...userUpdateForm,email:ev.target.value})} />
                           <FormLabel>
                            Role
                           </FormLabel>
                            <Select value={userUpdateForm.role} onChange={(e,v)=>setUserUpdateForm({...userUpdateForm,role:v})}>
                                <Option value="user">User</Option>
                                <Option value="admin">Admin</Option>
                            </Select>

                        <Button onClick={()=>onChangeUser()}>Submit</Button>
                        
                    </form>
            </ModalDialog>
        </Modal>                    


        <Table 
        aria-label="table variants"
        variant='outlined'
        stripe="odd"
        hoverRow
        color='neutral'
        stickyHeader
        stickyFooter
        >
        <thead>
          <tr>
            <th style={{width:'20px'}}>#</th>        
            <th>I.F.O</th>
            <th>Email</th>
            <th>Status</th>
            
            <th  style={{textAlign:'right',width:'300px'}}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button onClick={()=>{setOpenModal(true)}} variant='solid' color="success"><AddIcon /></Button>
                    </Grid>
                    <Grid item>
                        {   
                        selected.length ?
                        <Button onClick={()=>removeSelected()} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                        :""
                        }
                    </Grid>
                </Grid>   
            </th>
          </tr>
        </thead>
        <tbody>
            {
                dataList.length ? 
                    dataList.map((user)=>{
                      return(  
                        <tr>
                            <td><Checkbox /></td>    
                            <td>{user.name} {user.last_name}</td>
                            <td>
                                {user.email}
                            </td>
                            <td>
                            <Typography
                            color={(user.status=='active') ? 'success':'danger'}
                            level="body-md"
                            variant="soft"
                            noWrap
                            >
                                    {user.status}  
                            </Typography>
                            </td>
                            <td>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button onClick={()=>changeUser(user)} variant='solid' color="success"><EditIcon /></Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={()=>blockUser(user,(user.status=='active') ? true : false )} variant='solid' color="warning">{ (user.status=='active') ? <HttpsIcon /> : <LockOpenIcon /> }</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={()=>changePassword(user)} variant='solid' color="primary"><KeyIcon /></Button>
                                        </Grid>
                                      
                                        <Grid item>
                                            <Button onClick={()=>deleteUser(user)} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                                        </Grid>
                                    </Grid>
                            </td>
                        </tr>
                      )  
                    })
                    :<tr key={Date.now()}>
                    </tr>
            }
        </tbody>
      </Table>

      {
        
      <Pagination 
        current={pagination.current_page} 
        total={pagination.total} 
        className='pagination' 
        pageSize={1} 
        onChange={(current)=>{changePage(current)}}
        prevIcon=<KeyboardArrowLeftIcon/>
        nextIcon=<KeyboardArrowRightIcon/>
        jumpPrevIcon=<KeyboardDoubleArrowLeftIcon style={{position:'relative',top:'5px'}}/>
        jumpNextIcon=<KeyboardDoubleArrowRightIcon style={{position:'relative',top:'5px'}}/>
    
      />
      
      }
    </div>
  )
}

export default Main