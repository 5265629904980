import React,{useEffect, useState,useContext} from 'react'
import './Sidebar.css'

import { Link } from 'react-router-dom';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Avatar from '@mui/joy/Avatar';
import ListItemContent from '@mui/joy/ListItemContent';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useTranslation } from 'react-i18next';


const Sidebar = () => { 

  const { t,i18n } = useTranslation();
  const [windowHeight,setWindowHeight] = useState();
  const [user,setUser] = useState(null);

  useEffect(()=>{

    let sHeight = document.body.scrollHeight;
    let wHeight = window.innerHeight;
    if(sHeight >= wHeight){
      setWindowHeight(sHeight);
    }else{
      setWindowHeight(wHeight);
    }

    if(localStorage.user){
      let localStorageUser = JSON.parse(localStorage.user);
      setUser(localStorageUser);  
     }

  },[])

  window.addEventListener('resize',function(e){
    setWindowHeight(e.target.innerHeight);
  })

  return (
    <div className='sidebar' style={{height:windowHeight}}>


<AccordionGroup
      variant="string"
      transition="0.2s"
      color="danger"
      sx={{
        maxWidth: 400,
        [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
          {
            paddingBlock: '1rem', 
          },
        [`& .${accordionSummaryClasses.button}`]: {
          paddingBlock: '0.6rem',
        },
        [`& .${accordionSummaryClasses.button}:hover`]: {
          bgcolor: 'rgb(17,20,23)',
        },
      }}
    >
      <Accordion defaultExpanded={true}>
        <AccordionSummary className="sidebar-menu-item">
            <ArticleIcon style={{color:'#fff'}} fontSize="small"/>
          <ListItemContent className="menu-item-content">
            <Typography level="title-md" color="warning">{t('decision')}</Typography>
            <Typography level="body-sm" color="warning">
              {t('RFS Decisions')}
            </Typography>
          </ListItemContent>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1.5}>
             <Link to="decision?pageType=default" style={{color:'#fff',textDecoration:'none'}}>
                  <span style={{marginLeft:'40px'}}>{t('decision')}</span> 
            </Link>
          </Stack>
        </AccordionDetails>
      </Accordion>


      



      {
        (user && user.role=='admin') ?
      <Accordion defaultExpanded={true}>
        <AccordionSummary className="sidebar-menu-item">
          <SettingsIcon style={{color:'#fff'}} fontSize="small"/>
          <ListItemContent className="menu-item-content">
            <Typography level="title-md" >{t('administration')}</Typography>
            <Typography level="body-sm" >
              {t('Technical editing')}
            </Typography>
          </ListItemContent>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1.5}>
            <Link to="admin/decision/dashboard" style={{color:'#fff',textDecoration:'none'}}>    
              <span style={{marginLeft:'40px'}}> {t('decision')}</span>
            </Link>  
            <Link to="admin/user/main" style={{color:'#fff',textDecoration:'none'}}>
                <span style={{marginLeft:'40px'}}>{t('Users')}</span> 
            </Link>
          </Stack>
        </AccordionDetails>
      </Accordion>
      :""
      } 

    </AccordionGroup>


    </div>
  )
}

export default Sidebar