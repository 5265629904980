import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Input, Button, Checkbox, Typography, Alert, Modal, ModalDialog, ModalClose, FormLabel, Select, Option } from '@mui/joy';
import Table from '@mui/joy/Table';

import { Search as SearchIcon, Add as AddIcon,DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import HttpsIcon from '@mui/icons-material/Https';
import KeyIcon from '@mui/icons-material/Key';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Pagination from 'rc-pagination/lib/Pagination';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import axios from 'axios';


const  Main = () => {

    const [selected,setSelected] = useState([]);
    const { t } = useTranslation();
    const createDecision= () => {}

    const removeSelected = () => {
        console.log(selected);
        Swal.fire({
        title: t("Are you sure")+"?",
        text: t("You won't be able to revert this")+"!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it")+"!",
    }).then((result) => {
        if (result.isConfirmed){
            
            
                axios.post('admin/decision/service/remove-selected/',{payload:selected}).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            setSelected([]);
                            setDataList([]);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success"
                            });
                        }
                });

           
        }
    });

    } 

    const [dataList,setDataList] = useState([]);
    const [reload,setReload] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const [passwordModal,setPasswordModal] = useState(false);
    const [updateServiceModal,setUpdateServiceModal] = useState(false);

    const [userModal,setUserModal] = useState(false);
    

 
    
    const [serviceForm,setServiceForm] = useState({
        name:'',
    });

    const [serviceUpdateForm,setServiceUpdateForm] = useState({
        name:'',
        id:'',
    });

    const changeService = (service) => {
        setServiceUpdateForm({
            name:service.name,
            id:service.id,
        });
        setValue('updateServiceName',service.name);
        setUpdateServiceModal(true);
    }

    const [pagination,setPagination] = useState({
        total:0,
        rowsPerPage:0,
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
      } = useForm();  

    const [searchParams,setSearchParams] = useSearchParams();
    let currentPageNumber  = searchParams.get('page') ? parseInt(searchParams.get('page')):1;
    
    const[pageNumber,setPageNumber] = useState(currentPageNumber);

    useEffect(() => {
        axios.get('/admin/decision/service?page='+pageNumber).then((response) => {
            setDataList(response.data.data);
           // console.log(response.data.total,response.data.current_page,response.data.per_page);
            setPagination({
                current_page:response.data.current_page,
                per_page:response.data.per_page,
                total:response.data.total,
            })
        });
    },[pageNumber,reload]);


    const onChangeUser = () => {
        axios.post('/admin/decision/service/update',serviceUpdateForm).then((res) => {
            setUpdateServiceModal(false);
            if(res.data.message=='success'){
                Swal.fire({
                    title: t("User Changed!"),
                    text: t("User Changed successfully"),
                    icon: "success",
                    timer:1500,
                });
                setReload(!reload);
            }else{
                Swal.fire({
                    title: t("Error!"),
                    text: t("User Not  Changed"),
                    icon: "error",
                    timer:1500,
                });
           }
        });


    }

    const onCreateService = () => {
        axios.post('/admin/decision/service/create',serviceForm).then((res) => {
            setValue('name','');
            setServiceForm({
                name:'',
            });
            setOpenModal(false);
            if(res.data.message=='success'){
                
                Swal.fire({
                    title: t("Service Created!"),
                    text: t("Service Created successfully"),
                    icon: "success",
                    timer:1500,
                });
                setReload(!reload);
           
            }else{
                Swal.fire({
                    title: t("Error!"),
                    text: t("Service Not  Created"),
                    icon: "error",
                    timer:1500,
                });
           }
        });
    }

    const deleteService = (service) => {
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to delete user")+" "+service.name+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it")+"!",
          }).then((result) => {
            if (result.isConfirmed){
                let link = '/admin/decision/service/remove/'+service.id;
                axios.delete(link,{id:service.id}).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            setSelected([]);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success"
                            });
                        }
                });

            }
          });
    }

    const changePage = (page) => {
       // setSelected([]);
        setSearchParams({page:page})
        setPageNumber(page);

    }


    const setRecordList = (item,action,all=false) => {
        let selectedData = [];
        if(all){
            selectedData =  dataList.map((el,index)=>{
                el.isChecked = action;
                if(action){
                    if(!selected.includes(el.id)){
                        selected.push(el.id)
                    }
                }else{
                    selected.map((iden,index)=>{
                        if(iden==el.id){
                            selected.splice(index,1);
                        }
                    })
                }
                return el;
            });
        }else{
            selectedData =  dataList.map((el) => {
                if(el.id==item.id){
                    el.isChecked = action;
                    if(action){
                        selected.push(el.id);
                    }else{
                        selected.map((iden,index) => {
                            if(iden==el.id){
                                selected.splice(index,1);
                            }
                        })
                    }
                }
                return el;
            });
        }
        
        setDataList(selectedData);
      
      }


    return (
    <div>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={passwordModal}
                onClose={() => setPasswordModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >   
                <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                <form
                       
                    >
                                <FormLabel>* Parol {
                                    errors.newPassword && errors.newPassword.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            {       
                                errors.newPassword && errors.newPassword.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                          
                            <FormLabel>* Parol Takrorlang {
                                errors.repeatNewPassword && errors.repeatNewPassword.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            {       
                                errors.repeatNewPassword && errors.repeatNewPassword.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                             
                        <Button type="submit" onClick={()=>{}}>Submit</Button>
                    </form>
              </ModalDialog>  
            </Modal>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                    <form
                    onSubmit={handleSubmit(onCreateService)}
                    >
                            <FormLabel>* Xizmat {
                                errors.name && errors.name.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            {       
                                errors.name && errors.name.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("name", { required: true,maxLength:30, })} value={serviceForm.name} variant="outlined" color="primary" onChange={(ev)=>setServiceForm({...serviceForm,name:ev.target.value})} />
                        <Button type="submit" onClick={() => onCreateService()}>Submit</Button>
                    </form>
            </ModalDialog>
        </Modal>
         <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={updateServiceModal}
                onClose={() => setUpdateServiceModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                    <form>
                            <FormLabel>* Xizmat {
                                
                                errors.updateServiceName && errors.updateServiceName.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                            
                            {       
                                errors.updateServiceName && errors.updateServiceName.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("updateServiceName", { required: true,maxLength:30, })}  variant="outlined" color="primary" onChange={(ev)=>setServiceUpdateForm({...serviceUpdateForm,name:ev.target.value})} />
                           
                            

                        <Button onClick={()=>onChangeUser()}>Submit</Button>
                        
                    </form>
            </ModalDialog>
        </Modal>                    


        <Table 
        aria-label="table variants"
        variant='outlined'
        stripe="odd"
        hoverRow
        color='neutral'
        stickyHeader
        stickyFooter
        >
        <thead>
          <tr>
            <th style={{width:'20px'}}>#</th>        
            <th>Xizmat</th>   
            <th style = {{textAlign:'right',width:'300px'}}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button onClick={()=>{setOpenModal(true)}} variant='solid' color="success"><AddIcon /></Button>
                    </Grid>
                    <Grid item>
                        {   
                        selected.length ?
                        <Button onClick={()=>removeSelected()} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                        :""
                        }
                    </Grid>
                </Grid>   
            </th>
          </tr>
        </thead>
        <tbody>
            {
                dataList.length ? 
                    dataList.map((service)=>{
                      return(  
                        <tr>
                            <td><Checkbox defaultChecked={false} onChange={(e)=> setRecordList(service,e.target.checked)}/></td>    
                            <td>{service.name}</td>
                         
                        
                            <td>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Button onClick={()=>changeService(service)} variant='solid' color="success"><EditIcon /></Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={()=>deleteService(service)} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                                        </Grid>
                                    </Grid>
                            </td>
                        </tr>
                      )  
                    })
                    :<tr key={Date.now()}>
                    </tr>
            }
        </tbody>
      </Table>

      {
        
      <Pagination 
        current={pagination.current_page} 
        total={pagination.total} 
        className='pagination' 
        pageSize={10} 
        onChange={(current)=>{changePage(current)}}
        prevIcon=<KeyboardArrowLeftIcon/>
        nextIcon=<KeyboardArrowRightIcon/>
        jumpPrevIcon=<KeyboardDoubleArrowLeftIcon style={{position:'relative',top:'5px'}}/>
        jumpNextIcon=<KeyboardDoubleArrowRightIcon style={{position:'relative',top:'5px'}}/>
    
      />
      
      }
    </div>
  )
}

export default Main