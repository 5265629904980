import  React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter,Route,Routes,Link } from 'react-router-dom';
import './App.css';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import { Grid } from '@mui/joy';
import Content from './Layout/Content';

import ReportIcon from '@mui/icons-material/Report';

import Decision from './Module/Admin/Decision/Decision';
import DecisionList from './Module/Admin/Decision/DecisionList';
import DecisionAdm from './Module/Admin/Decision/DecisionAdm';
import Dashboard from './Module/Admin/Decision/Dashboard';
import Area from './Module/Admin/Decision/Area';
import Company from './Module/Admin/Decision/Company';
import Technology from './Module/Admin/Decision/Technology';
import DecisionType from './Module/Admin/Decision/DecisionType';
import axios from 'axios';
//import CommonDecision from './Module/Common/Decision';
import CommonDecision from './Module/Common/DecisionCommon';
import DecisionView from './Module/Common/DecisionView';
import Login from './Module/Common/Login';
import UserList from './Module/Admin/User/Main';
import DecisionRelated from './Module/Admin/Decision/DecisionRelated';
import Service from './Module/Admin/Service/Main';
import Profile from './Module/Common/Profile';






//axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'http://crm.rchs.uz/api/public/index.php/api';
axios.defaults.headers.common['Authorization'] = '123';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;


function App(){


  let defaultUser = {
    email:"",
    password:"",
    isAuth:false,
    full_name:"",
    name:"",
    last_name:"",
    role:"",
    language:"uz",
    id:"",
  }

 //localStorage.removeItem('user');
 if(localStorage.user){

  let localStorageUser = JSON.parse(localStorage.user);
  console.log(localStorageUser,'LST');

  defaultUser = {
    email:localStorageUser.email,
    password:localStorageUser.password,
    full_name:localStorageUser.full_name,
    name:localStorageUser.name,
    last_name:localStorageUser.last_name,
    role:localStorageUser.role,
    isAuth:true,
    language:localStorageUser.language,
    id:localStorageUser.id,
  };
  
 }

  const [user,setUser] = useState(defaultUser);

  return (
    <BrowserRouter>    
          {
            (user.isAuth) ?
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid xs={12}>
              <Header user={user} setUser={setUser} />
            </Grid>
            <Grid xs={4} md={3} lg={2} style={{maxWidth:'300px'}}>
              <Sidebar />
            </Grid>
            <Grid xs={8} md={9} lg={10}>      
                <Content>
                      <Routes>
                        <Route path="/admin/decision/dashboard" element={<Dashboard />} />
                        <Route path="/admin/decision/area" element={<Area />} />
                        <Route path="/admin/decision/company" element={<Company />} />
                        <Route path="/admin/decision/type" element={<DecisionType />} />
                        <Route path="/admin/decision/technology" element={<Technology />} />
                        <Route path="/admin/decision/index" element={<Decision />} />
                        <Route path="/admin/decision/list" element={<DecisionList />} />
                        <Route path="/admin/decision/adm" element={<DecisionAdm />} />
                        <Route path="/admin/decision/service" element={<Service />} />
                        <Route path="/admin/decision/related/:id" element={<DecisionRelated />} />
                        <Route path="/admin/user/main" element={<UserList />} />
                        <Route path="/decision/" element={<CommonDecision />} />
                        <Route path="/decision/view/:id" element={<DecisionView />} />
                        <Route path="/user" element={<Profile />} />
                      </Routes>
                </Content>              
            </Grid>
          </Grid>
          :  
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid xs={12}>
              <Content>
                  <Login user={user} setUser={setUser} />
              </Content>
            </Grid>
            
          </Grid>
          }  
    </BrowserRouter>
  );
}

export default App;
