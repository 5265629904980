import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';


//import { createStore,applyMiddleware } from 'redux';
//import { thunk } from 'redux-thunk';
/*

const defaultState = {
  loading:false,
  user:{},
  language:"uz",
}


const reducer = (state = defaultState,action) => {
  switch(action.type){
    case "LOADING":
      return {...state,loading:action.payload};
    case "LANGUAGE":
      return {...state,language:action.payload};  
    default:
      return state;  
  }
}

const store  = createStore(reducer,applyMiddleware(thunk));

*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
