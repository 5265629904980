import axios from "axios";

export default {
    getCookie() {
        return axios.get("sanctum/csrf-cookie",{
           // baseURL:'http://127.0.0.1:8000/'
            baseURL:'http://crm.rchs.uz/api/public/index.php/'
        });
    }
};

