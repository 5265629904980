import React,{useState,useEffect} from 'react';
import {  Input, Grid, Button,Checkbox, Box, Select,Option,IconButton,FormControl, Textarea } from '@mui/joy';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AsyncSelect from 'react-select/async';
import { useForm } from 'react-hook-form';
import Upload from 'rc-upload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import {Save as SaveIcon} from '@mui/icons-material';
import dayjs from 'dayjs';
import axios from 'axios';
import Swal from 'sweetalert2';


const  DecisionCreate = ({setOpenModal,reload,setReload}) => {

  

  let defaultRecord = {
        acceptance_date:"",
        area:[],
        deadline:"", 
        decision_type_id:"",
        description:"",
        developer:"",
        frequency: [],
        id:"",
        import_company:"",
        importer:"", 
        made_company:"",
        number1: "",
        status:"", 
        technology:[],
        type: {},
        updated_at:"",
        user: "",
        user_company : "", 
        issue:"",
        fileList:[],
        related:[],
        relationDefination:"",
        service:"",
        relationStatus:"",
        relationDirection:true,
  }

  const[showDecision,setShowDecision] = useState(true);
    const[showFrequency,setShowFrequency] = useState(false);
    const[showRelated,setShowRelated] = useState(false);
    const[showService,setShowService] = useState(false);
    const[showTechnology,setShowTechnology] = useState(false);
    
    const [uploadedFiles,setUploadedFiles] = useState([]);
    const [uploadedError,setUploadedError] = useState([]);
    const [area,setArea] = useState(false);
    const [developer,setDeveloper] = useState(false);
    const [importer,setImporter] = useState(false);
    const [deadline,setDeadline] = useState(false);

    const [record,setRecord] = useState(defaultRecord);

   useEffect(()=>{

        setValue('decisionType',record.type);
   
    },[])

    useEffect(()=>{
        if(!deadline){
            record.deadline = null;
        }
    },[deadline]);

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        formState: { errors },
      } = useForm();  

    const tabHandle = (tab) => {
        if(tab==1){
          setShowDecision(true);
          setShowRelated(false);
          setShowFrequency(false);
          setShowService(false);
          setShowTechnology(false);
        }
        if(tab==2){
            setShowFrequency(true);
            setShowRelated(false);
            setShowDecision(false);
            setShowService(false);
            setShowTechnology(false);
        }
        if(tab==3){
            setShowRelated(true);
            setShowFrequency(false);
            setShowDecision(false);
            setShowService(false);
            setShowTechnology(false);
        }
        if(tab==4){
          setShowRelated(false);
          setShowFrequency(false);
          setShowDecision(false);
          setShowTechnology(false);
          setShowService(true);

        }
        if(tab==5){
            setShowRelated(false);
            setShowFrequency(false);
            setShowDecision(false);
            setShowService(false);
            setShowTechnology(true);
        }
    
      }

      const [dateString,setDateString] = useState({
        issue:"",
        deadline:"",
      });

    /*
    
    */

    const getAreaList = (input) => {

        return axios.get(`/admin/decision/area/search?q=${input}`).then((response) => {
            let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
            return options;
        });

    }

    const [defaultTypeList,setDefaultTypeList] = useState([]);

    const setFrequencyValue = (item,field,value) => {
        
        let fr = record.frequency.map((e,i) => {
            
            if(e.id == item.id){
              record.frequency[i][field] = value;
            }
            return e;
      
        });
      
        setRecord({...record,frequency:fr});
      
      }

      
      const deleteFrequency = (frequencyItem) => 
      {
      
        axios.post('/admin/decision/remove-single-frequency',{id:frequencyItem.id}).then((response)=>{
            console.log(response);
            let data = record.frequency.filter((fr) => {
                return fr.id != frequencyItem.id
            })
        
            setRecord({...record,frequency:data});
        })

         
      
      }

      const uploadProps = {

        name:'decision-document',
        onStart : function(){
            console.log('start');
        },
    
        onSuccess : function(result,file){
           // uploadedFiles.push(result);
            setUploadedFiles([...uploadedFiles,result]);
            console.log(uploadedFiles);
            //setRecord({...record,files:[...record.files,result]});
           //setRecord({...record,file:[...record.fileList,result]});
        },
    
        onError : function(err,response,file){
            setUploadedError([...uploadedError,file])
        },

        action: 'http://127.0.0.1:8000/api/admin/decision/file/upload',
        multiple:true,
    
      }
    
      const deleteFile = (file) => {

        axios.post('/admin/decision/file/remove',file).then((response) => {
            if(response){

                let files = uploadedFiles.filter((item) => item.id!=file.id);
                let recordFile = record.files.filter((item) => item.id!=file.id);
                setUploadedFiles(files);
               //setRecord({...record,files:recordFile});

            }
        });

      }
    
    
      const deleteUploadedError = (file) => {
          
          let errors = uploadedError.filter((item) => item.uid!=file.uid);
          setUploadedError(errors);
    
      };
    
     
    
    const getDecisionList = (input) => {
    
        return axios.get(`/admin/decision/search?q=${input}`).then((response) => {
            let options = response.data.data.map((item) => ({ value:item.id, label: item.number }));
            return options;
        });
    
    }
    
    
    const getDeveloperList = (input) => {
        return axios.get(`/admin/decision/company/search?q=${input}`).then((response) => {
            let options = response.data.data.map((item) => ({ value:item.id, label: item.name }));
            return options;
        });
    }
    
    
    const getCompanyList = (input) => {
     
        return axios.get(`/admin/decision/company/search?q=${input}`).then((response) => {
            let options = response.data.data.map((item) => ({ value:item.id, label: item.name }));
            return options;
        });
    
    }
    const getTechnologyList = (input) =>{
        return axios.get(`/admin/decision/technology/search?q=${input}`).then((response) => {
            let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
            return options;
        });
    }
    
    const getServiceList = (input) => {
     
      return axios.get(`/admin/decision/service/search?q=${input}`).then((response) => {
          let options = response.data.data.map((item) => ({ value:item.id, label: item.name }));
          return options;
      });
    
    }
    
    
    const getTypeList = () => {

      return axios.get(`/admin/decision/type`).then((response) => {
          let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
          return options;
      });
    
    }


    const setDateTime = (e,deadline=true) => {
        if(e){
            if(deadline){
                setRecord({...record,deadline:e}); setDateString({...dateString,deadline:e.$D+'-'+e.$M+'-'+e.$y})
            }else{
                setValue('decisionIssue',e);setRecord({...record,issue:e}); setDateString({...dateString,issue:e.$D+'-'+e.$M+'-'+e.$y});
            }
            
        }
        
    }


    const save = (data) => {



        record.issueString = dateString.issue ? dateString.issue : null;
        record.deadlineString = deadline ? dateString.deadline:null;
        record.fileList = uploadedFiles;
       // console.log(record); 
       
        setRecord({...record,fileList:uploadedFiles});
        console.log(uploadedFiles,'files');
        return axios.post(`/admin/decision/create`,record).then((response) => {
            if(response.data.message == "success"){
              setRecord(defaultRecord);
              setValue('number','');
              setUploadedFiles([]);
              setOpenModal(false);
              setReload(!reload);
              Swal.fire({
                title: "Good job!",
                text: "You clicked the button!",
                icon: "success",
                timer: 1500,
              });

            }
        });

       

    }
    
  /* */

  return (
    <div>
        <Box
                sx={{
                    maxWidth: '100%',
                }}
            >
            <form onSubmit={handleSubmit(save)}>


            
                <div>
                    <Button onClick={()=>tabHandle(1)} variant={showDecision ? "outlined":""} >Qaror</Button>
                    <Button onClick={()=>tabHandle(2)} variant={showFrequency ? "outlined":""}>Chastota</Button>
                    <Button onClick={()=>tabHandle(3)} variant={showRelated ? "outlined":""}>Aloqador xujjatlar</Button>
                    <Button onClick={()=>tabHandle(4)} variant={showService ? "outlined":""}>Xizmat</Button>
                    <Button onClick={()=>tabHandle(5)} variant={showTechnology ? "outlined":""}>Texnologiya</Button>
                    {                
                        showDecision ?
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <label id="demo-select-small-label1">*Qaror Raqami {errors.number && <span style={{color:'red'}}>This field is required</span>}</label>
                                            <Input
                                                labelId="demo-select-small-label1"
                                                variant="outlined"
                                                {...register("number", { required : true })}
                                                value={record.number}
                                                onChange={(e)=>{ setValue('number',e.target.value); setRecord({...record,number:e.target.value}) }}
                                               
                                                sx={{ width: '240px' }} />
                                    </Grid>
                                    <Grid item xs={6}>
                                            <label id="demo-select-small-label12321">*Qaror turi {errors.decisionType && <span style={{color:'red'}}>This field is required</span>}</label>
                                            <FormControl>
                                                <AsyncSelect {...register("decisionType", { required : true })} sx={{width:'240px'}} className='async-select' onChange = { (e) => { setRecord({...record,type:e});setValue('decisionType',e) } } value={record.type}  defaultOptions={defaultTypeList} loadOptions={getTypeList} />
                                            </FormControl>
                                </Grid>
                                </Grid>  

                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <label id="demo-select-small-label2">*Qabul qilingan sana {errors.decisionIssue && <span style={{color:'red'}}>This field is required</span>}</label><br></br>
                                                    <DatePicker  {...register("decisionIssue", { required : true })} value={dayjs(record.issue)} onChange={ (e) => setDateTime(e,false) } defaultValue={record.issue} labelId="demo-select-small-label2"  sx={{ width: (deadline) ? '240px' : '490px'}} slotProps={{field: { clearable: true },textField:{size:"small"}}}/>
                                                </LocalizationProvider>
                                    </Grid> 
                                    <Grid item xs={6}>
                                        {
                                            deadline ?
                                            <>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <label id="demo-select-small-label234">Muddat</label><br></br>
                                                    {
                                                        /*
                                                            https://stackoverflow.com/questions/70041109/how-can-i-clear-material-ui-datepicker-input
                                                        */
                                                    }
                                                    <DatePicker  value={dayjs(record.deadline)}   onChange={ (e) =>  setDateTime(e) } defaultValue={record.deadline} labelId="demo-select-small-label2"   slotProps={{field: { clearable: true },textField:{size:"small"}}} componentsProps={{actionBar:{action:['clear']}}} />
                                                </LocalizationProvider>
                                            </> 
                                            :''
                                        }
                                        </Grid> 
                                </Grid>
                                
                            
                
                                <Grid container spacing={1}>
                                <Grid item xs={4}>
                                        {
                                            area ?
                                                <div style={{width:'160px'}}>
                                                <label id="demo-select-small-label2">Xudud</label>
                                                <AsyncSelect sx={{width:'160px'}} className='async-select' onChange = { (e) => { setRecord({...record,area:e}) } } value={record.area}  loadOptions={getAreaList} />
                                                </div>
                                            :''
                                        }
                                </Grid>
                                <Grid item xs={4}>
                                        {      
                                            developer ?
                                            <div style={{width:'160px'}}>
                                            <label id="demo-select-small-label2">Ishlab Chiqaruvchi</label>
                                            <AsyncSelect sx={{width:'160px'}} className='async-select' onChange ={ (e) => { setRecord({...record,developer:e}) } } value={record.developer}  loadOptions={getDeveloperList} />
                                            </div> 
                                            : ''
                                        }
                                        </Grid> 
                                        <Grid item xs={4}>
                                        {
                                            importer ?
                                                <div style={{width:'160px'}}>
                                                <label id="demo-select-small-label2">Olib Keluvchi</label>
                                                <AsyncSelect sx={{width:'160px'}} className='async-select' styles={{backgroundColor:'red'}}  onChange = { (e) => { setRecord({...record,importer:e}) } } value={record.importer}  loadOptions={getCompanyList} />
                                                </div>
                                            :''
                                        }
                                        </Grid> 
                                </Grid>          
                                <Grid container>        
                                <Grid item xs={12} spacing={3}>
                                    <label id="filled-start-adornment">Izox</label>
                                    <FormControl id="outlined-multiline-static">
                                            <Textarea value={record.description} minRows={3} style={{width:'490px'}} onChange={ (e) => { setRecord({...record,description:e.target.value}) } } />
                                    </FormControl>    
                                </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                <Grid item xs={12} spacing={3} style={{marginTop:'15px'}}>
                                        <Button style={{backgroundColor:'#fff',border:'1px dashed #ccc',paddingTop:'20px',paddingBottom:'24px',display:'none'}} fullWidth={true} variant="contained" type="text" companent="label">
                                            <ContentCopyIcon style={{position:'absolute',marginTop:'-20px',color:'rgb(195 202 214)'}} />
                                            <span style={{position:'absolute',marginTop:'40px',color:'rgba(0, 0, 0, 0.6)'}}>
                                                Faylni Yuklash
                                            </span>    
                                            <FormControl id="file-upload">
                                                <Input sx={{ width: '100%',opacity:0}} type="file" />
                                            </FormControl>
                                        </Button>
                                    <Upload { ...uploadProps}>
                                        <Button fullWidth={true}  companent="label">
                                            Fayln Yuklash
                                        </Button>
                                    </Upload>
                                    {
                                        record.files ? 
                                            record.files.map((f) => {
                                                return (<p>
                                                    {f.file_name}
                                                    <IconButton onClick={()=>deleteFile(f)} aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </p>)
                                            })
                                        :""
                                    }
                                    {
                                        uploadedFiles.map((f) => {
                                            return (<p>
                                                {f.file_name}
                                                <IconButton onClick={() => deleteFile(f)} aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </p>)
                                        })
                                    }
                                    {
                                        uploadedError.map((f) => {
                                            return (<p>
                                                <span style={{color:'red'}}>{f.name}</span>
                                                <IconButton onClick={()=>deleteUploadedError(f)} aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </p>)
                                        })
                                    }
                                </Grid>
                                </Grid>
                            </Grid>

                            <Grid xs={12}>
                            
                                    <Grid container spacing={2}>
                                            
                                            <Grid item xs={3}>
                                                <label id="area-field">
                                                <Checkbox checked={area} onClick={()=>setArea(!area)}/>
                                                    <span style={{position:'relative',top:'-5px',left:'5px'}}>Xudud</span>
                                                </label>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <label id="area-field">
                                                    <Checkbox checked={developer} onClick={()=>setDeveloper(!developer)}/>
                                                    <span style={{position:'relative',top:'-5px',left:'5px'}}>Ishlab Chiqruvchi</span>
                                                </label>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <label id="area-field">
                                                    <Checkbox checked={importer} onClick={()=>setImporter(!importer)}/>
                                                    <span style={{position:'relative',top:'-5px',left:'5px'}}>Olib Keluvchiimport</span> 
                                                </label>
                                            </Grid>
                                            <Grid item xs={3}> 
                                                <label id="area-field">
                                                    <Checkbox checked={deadline} onClick={()=>setDeadline(!deadline)}/>
                                                    <span style={{position:'relative',top:'-5px',left:'5px'}}>Muddat</span>
                                                </label>
                                            </Grid>
                                    </Grid>

                                        <Grid item xs={2}>
                                            <Button type='submit' style={{float:'right',marginTop:'30px'}}  startIcon={<SaveIcon />}>Saqlash</Button>   
                                        </Grid>  
                            </Grid>
                        
                                        
                            </Grid>  
                            
                        :""
                    }
                    {                
                        showFrequency ?
                            <div>                                              
                                        <Grid container>
                                        {
                                            record.frequency.map((frequencyItem) => { return (
                                                <Grid container style={{marginBottom:30}} spacing={2}>
                                                    <Grid item xs={4}>
                                                        <label id="demo-select-small-label1">Chastota </label>
                                                        <FormControl id="outlined-basic">
                                                            <Input
                                                                onChange = {(e) => {setFrequencyValue(frequencyItem,'frequency_start',e.target.value)}}
                                                                value={frequencyItem.frequency_start}
                                                                labelId="demo-select-small-label1"
                                                                variant="outlined"
                                                                sx={{ width: '100%' }} />
                                                        </FormControl>
                                                    </Grid>    
                                                    <Grid item xs={4}>
                                                        <label id="demo-select-small-label1">Oraliq</label>
                                                        <FormControl id="outlined-basic">
                                                            <Input
                                                                onChange = {(e) => {setFrequencyValue(frequencyItem,'frequency_end',e.target.value)}}
                                                            
                                                                value={frequencyItem.frequency_end}
                                                                labelId="demo-select-small-label1"
                                                                variant="outlined"
                                                                sx={{ width: '100%' }} />

                                                        </FormControl>
                                                    </Grid> 
                                                    <Grid item xs={2}>
                                                    <label id="demo-select-small-label">Birlik </label>
                                                        <Select
                                                        labelId="demo-select-small-label"
                                                        id="demo-select-small"
                                                        label="Birlik"
                                                        onChange = {(e,v) => { setFrequencyValue(frequencyItem,'measure',v); }}
                                                        sx={{ width: '100%' }}
                                                        value={frequencyItem.measure}
                                                        >
                                                        <Option value='khz'>KHz</Option>
                                                        <Option value='mhz'>MHz</Option>
                                                        <Option value='ghz'>GHz</Option>
                                                        </Select>
                                                    </Grid>  
                                                    <Grid item xs={2}>
                                                        <IconButton color="secondary" style={{marginTop:'15px'}}>
                                                            <DeleteIcon  onClick={() => deleteFrequency(frequencyItem) } />
                                                        </IconButton>
                                                    </Grid>         
                                                </Grid>
                                            );})
                                        }
                                        </Grid>
                                        <Button variant='contained' style={{float:'right'}} color="warning" onClick={() => setRecord({...record,frequency:[...record.frequency,{id:Date.now(),frequency_start:'',frequency_end:''}]})}>
                                            <AddCircleOutlineIcon />
                                        </Button>
                            </div>
                        :""
                    }
                    {                
                        showRelated ?
                            <div>
                                Aloqador xujjatlar
                                    <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container  spacing={1}>
                                                    <Grid item xs={4}>
                                                            <Select
                                                                labelId="demo-select-small-label1"
                                                                id="demo-select-small"
                                                                onChange={(e,v) => setRecord({...record,relationStatus:v})}
                                                                size='md'
                                                                sx={{ width: '160px' }}
                                                                value={record.relationStatus}
                                                            >
                                                                <Option value="">--</Option>
                                                                <Option value="cancel">Bekor qilish</Option>
                                                                <Option value="change">O'zgartirish</Option>
                                                                <Option value="other">Boshqa</Option>
                                                        </Select>
                                                    </Grid>    
                                                    <Grid item xs={1}>
                                                        <Button variant='contained'  onClick = {() => setRecord({...record,relationDirection:!record.relationDirection})}  style={{margin:'0 5px'}}>
                                                            {
                                                            record.relationDirection ? 
                                                                <KeyboardDoubleArrowRightIcon/>
                                                            :
                                                                <KeyboardDoubleArrowLeftIcon  />
                                                            }    
                                                        </Button>
                                                    </Grid>  
                                                    <Grid item xs={7}>
                                                        <AsyncSelect className='related-select-doc' isMulti onChange = { (e) => { setRecord({...record,related:e});console.log(e) }} defaultValue={record.related}  loadOptions={getDecisionList} />
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <label id="relation-definitation">Izox</label>
                                                        <FormControl id="outlined-multiline-static-relation-defination">
                                                            <Textarea value={record.relationDefination} minRows={3} style={{width:'540px'}}  onChange={ (e) => { setRecord({...record,relationDefination:e.target.value}) } } />
                                                        </FormControl>                 
                                                    </Grid>       
                                                </Grid>
                                        </Grid> 
                                    </Grid>           
                            </div>
                        :""
                    }
                    {
                        showService ? 
                        <div>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div>
                                            <label id="demo-select-small-label2">Xizmat turi</label>
                                            <AsyncSelect className='async-select' onChange ={ (e) => { setRecord({...record,service:e}) } } value={record.service}  loadOptions={getServiceList} />
                                        </div> 
                                    </Grid>     
                                </Grid>    
                        </div>
                        :""
                    }
                    {
                        showTechnology ? 
                        <div>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div>
                                            <label id="demo-select-small-label2">Xizmat turi 123</label>
                                            <AsyncSelect className='async-select' isMulti onChange ={ (e) => { setRecord({...record,technology:e}) } } value={record.technology}  loadOptions={getTechnologyList} />
                                        </div> 
                                    </Grid>     
                                </Grid>    
                        </div>
                        :""
                    }
                </div>

            </form>

            </Box>
    </div>
  )
}

export default DecisionCreate