import * as React from 'react';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import KeyIcon from '@mui/icons-material/Key';
import axios from 'axios';
import  Csrf  from '../../Api/Csrf';


const  Login = ({user,setUser}) => {
   
    const [message,setMessage] = React.useState("");
    const auth  = () => {

        Csrf.getCookie().then(() => {
          axios.post('/login',user).then((res) => {
              
                  /*
                      https://stackoverflow.com/questions/40988238/sending-the-bearer-token-with-axios
                  */

                  if(res.data.token){
                    
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+res.data.token;
              
                    setUser({...user,
                      isAuth:true,
                      full_name:res.data.user_name,
                      role:res.data.role,
                      language:res.data.language,
                      id:res.data.id,
                      name:res.data.user_name,
                      last_name:res.data.last_name,

                    });


                    let userData = {
                      ...user,
                      isAuth:true,
                      full_name:res.data.user_name,
                      name:res.data.name,
                      last_name:res.data.last_name,
                      role:res.data.role,
                      language:res.data.language,
                      id:res.data.id,
                    };

                    localStorage.setItem('user',JSON.stringify(userData));

                  }else{

                    setMessage(res.data.message);
                    setUser({...user,
                      isAuth:false,
                    });

                  }

              
              
              
          });
        })
       
    }

  return (
    <Card
      sx={{
        maxHeight: 'max-content',
        maxWidth: '500px',
        mx: 'auto',
        // to make the demo resizable
        overflow: 'auto',
        resize: 'horizontal',
      }}
      color="primary" variant="solid"
    >
      <Typography level="title-lg" startDecorator={<InfoOutlined />}>
        Authorization 
      </Typography>
      <Divider inset="none" />
      <CardContent
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
          gap: 1.5,
        }}
      >
        <FormControl sx={{ gridColumn: '1/-1' }}>
          <FormLabel>Login</FormLabel>
          <Input endDecorator={<CreditCardIcon />} onChange={(e)=>setUser({...user,email:e.target.value})} />
        </FormControl>
        <FormControl sx={{ gridColumn: '1/-1' }}>
          <FormLabel>Password</FormLabel>
          <Input endDecorator={<KeyIcon />} type="password" onChange={(e)=>setUser({...user,password:e.target.value})}/>
        </FormControl>
        <CardActions sx={{ gridColumn: '1/-1' }}>
          <Button variant="solid" onClick={() => auth()} color="warning">
            Login
          </Button>
        </CardActions>
        <i style={{color:'red'}}>{message}</i>
      </CardContent>
    </Card>
  )
}

export default Login