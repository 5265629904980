import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Input, Grid, Button, Checkbox, Modal, ModalDialog, ModalClose, Card, CardContent, CardActions, Typography, ButtonGroup } from '@mui/joy';
import Pagination from 'rc-pagination/lib/Pagination';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import { Search as SearchIcon, Add as AddIcon,DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import DecisionUpdate from './DecisionUpdate';
import DecisionCreate from './DecisionCreate';
import AsyncSelect from 'react-select/async';
import CachedIcon from '@mui/icons-material/Cached';
import Swal from 'sweetalert2';

const  DecisionList = () => {

    const { t } = useTranslation();
    const [reload,setReload] = useState(false);
    const [dataList,setDataList] = useState([]);
    const [openModal,setOpenModal] = useState(false);
    const [selectAll,setSelectAll] = useState(false);

    const [pagination,setPagination] = useState({
        total:0,
        rowsPerPage:0,
    });

    const [searchParams,setSearchParams] = useSearchParams();


    const defaultActiveRecord = {
        id:null,
    }

    const [activeRecord,setActiveRecord] = useState(defaultActiveRecord);

    let [selected,setSelected] = useState([]);
    let currentPageNumber  = searchParams.get('page') ? parseInt(searchParams.get('page')):1;
    
    const[pageNumber,setPageNumber] = useState(currentPageNumber);

    useEffect(() => {
        axios.get('/admin/decision/index').then((response) => {
          setDataList([...dataList,...response.data.data]);
          setPagination({rowsPerPage:response.data.per_page,total:response.data.total}); 
        });    
    },[]);

    useEffect(() => {
        axios.get('/admin/decision/index?page='+pageNumber).then((response) => {
          
            setDataList(response.data.data);
            setPagination({rowsPerPage:response.data.per_page,total:response.data.total}); 
        
        }); 
    
        axios.get(`/admin/decision/type`).then((response) => {
          let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
          //setDefaultTypeList(options);
        });
       
      },[pageNumber,reload]);


    const changePage = (page) => {
        setSelected([]);
        setSearchParams({page:page})
        setPageNumber(page);
    }

    const editSingleDecision = (item) => {
       if(item.type && item.type.id){
            item.type = {
                label:item.type.title,
                value:item.type.id,
            };
       }

       if(item.service && item.service.length){
            item.service = item.service.map((service)=>{
                return {
                    label:service.name,
                    value:service.id,
                }
            })
       }
       setActiveRecord(item);
       setOpenModal(true);
    }

    let defaultSearchForm = {

        number:"",
        name:"",
        area:"",
        page:1,
    
      }
    
    const [searchForm,setSearchForm] = useState(defaultSearchForm)

    const createDecision = () => {
        setActiveRecord(defaultActiveRecord);
        setOpenModal(true);
    }



    const removeSelected = () => {
    
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to revert this")+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it")+"!",
        }).then((result) => {

            if(result.isConfirmed){
                
                axios.post('admin/decision/remove-selected/',{payload:selected}).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            setSelected([]);
                            setDataList([]);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success"
                            });
                        }
                });
    
            }
        });
    }
    
   
    const setRecordList = (item,action,all=false) => {
         
            let selectedData = [];
            if(all){
                selectedData =  dataList.map((el,index) => {
                    el.isChecked = action;
                    if(action){
                        if(!selected.includes(el.id)){
                            selected.push(el.id)
                        }
                    }else{
                        selected.map((iden,index)=>{
                            if(iden==el.id){
                                selected.splice(index,1);
                            }
                        })
                    }
                    return el;
                });
            }else{
                selectedData =  dataList.map((el)=>{
                    if(el.id==item.id){
                        el.isChecked = action;
                        if(action){
                            selected.push(el.id);
                        }else{
                            setSelectAll(false);
                            selected.map((iden,index) => {
                                if(iden==el.id){
                                    selected.splice(index,1);
                                }
                            })

                        }
                    }
                    return el;
                });
            }
            
            setDataList(selectedData);
        
    }
    

    const removeRecord = () => {

    }

    const getAreaList = (input) => {

        return axios.get(`/admin/decision/area/search?q=${input}`).then((response) => {
            let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
            return options;
        });
    
    }

    const search = () => {
 
        let link = `admin/decision/part-search?number=${searchForm.number}&area=${searchForm.area}&name=${searchForm.name}&page=${pageNumber}`;
          axios.get(link).then((res) => {
            
            if(!res.data.data.length && pageNumber > 1){
                let page = pageNumber-1;
                setPageNumber(page);
                setSearchParams({page:page});
            }
            
            setPagination({rowsPerPage:res.data.per_page,total:res.data.total}); 
            setDataList(res.data.data);


        });
      
      }
      
      const clearSearch = () => {
              setSearchForm(defaultSearchForm);
              setSearchParams({pageType:'default'});
              window.location.reload();
      }

    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
              <ModalDialog variant="soft" size="lg">
                  <p>
                      <ModalClose variant="plain" sx={{ m: 1 }} />   
                  </p>
                      <Card style={{minHeight:'300px'}}>

                        <CardContent>
                            {
                                activeRecord.id ? 
                                    <DecisionUpdate 
                                        defaultRecord={activeRecord}
                                        setOpenModal = {setOpenModal}
                                        reload={reload}
                                        setReload={setReload}  
                                        />
                                :   
                                    <DecisionCreate 
                                        setOpenModal = {setOpenModal}
                                        defaultRecord = {activeRecord}
                                        reload={reload}
                                        setReload={setReload}  
                                     />
                            }


                        </CardContent>
                        <CardActions>
                                                          
                            

                        </CardActions>        
                      </Card>
              </ModalDialog>
          </Modal>
          <p>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Input placeholder='Qaror Raqami' onChange={(e)=>setSearchForm({...searchForm,number:e.target.value})}/>
            </Grid>
          
            <Grid item xs={3}>
              <AsyncSelect placeholder='Xudud' className='async-select' onChange={(e)=>{setSearchForm({...searchForm,area:e.value})}}  loadOptions={getAreaList} />
            </Grid>
            <Grid item xs={2}>

            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button onClick={search} variant="contained" color="success" style={{backgroundColor:'#277A1F',color:'#fff'}}><SearchIcon/></Button>
            
              <Button variant="contained" onClick={clearSearch} color="success"  style={{backgroundColor:'#c41c1c',color:'#fff'}}><CachedIcon /></Button>
            
            </ButtonGroup>
    
            </Grid>
          </Grid>
        </p>
            <Table size="md" variant="outlined" borderAxis="both"  style={{borderRadius:'5px'}}>
                <thead style={{borderRadius:'5px'}}>
                        <tr>
                            <th style={{width:'20px'}}><Checkbox value={selectAll} onChange={(e)=>{ setRecordList({},e.target.checked,true);setSelectAll(e.target.checked); }}  className='checking-area' /></th>
                            <th>Qaror raqami</th>
                            <th>Nomi</th>
                            <th>Xudud</th>
                            <th>Chastota</th>
                            <th>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button onClick={()=>{createDecision()}} variant='solid' color="success"><AddIcon /></Button>
                                </Grid>
                                <Grid item>
                                    {   
                                    selected.length ?
                                    <Button onClick={()=>removeSelected()} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                                    :""
                                    }
                                </Grid>
                            </Grid>      
                                
                                
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        dataList.map((item) => {
                            return (
                            <tr>
                                <td><Checkbox checked={item.isChecked ?? false} onChange={(e)=>setRecordList(item,e.target.checked)}  className='checking-area' /></td>
                                <td>{item.number}</td>
                                <td>{item.description}</td>
                                <td>
                                    {
                                        item.area.map((ar)=>{
                                        return <Typography
                                            color="warning"
                                            level="body-lg"
                                            variant="outlined"
                                            variant="soft"
                                            >
                                            {ar.title}
                                            </Typography>
                                            
                                        }) 
                                    }
                                </td>
                                <td>
                                {
                                    item.frequency.map((fr)=>{
                                       return <Typography
                                        color="success"
                                        level="body-lg"
                                        variant="outlined"
                                        variant="soft"
                                        >
                                           {fr.frequency_start}-{fr.frequency_end} {fr.measure}
                                        </Typography>
                                        
                                    }) 
                                }
                                </td>
                                <td>
                                <Grid container spacing={1}>
                                <Grid item>
                                    <Button onClick={()=>editSingleDecision(item)} variant='solid' color="success"><EditIcon /></Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={()=>removeRecord(item.id)} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                                </Grid>
                                </Grid>
                                </td>
                            </tr>
                            )
                        })
                        }
                    </tbody>
                </Table>
                <Pagination 
                prevIcon=<KeyboardArrowLeftIcon/>
                nextIcon=<KeyboardArrowRightIcon/>
                jumpPrevIcon=<KeyboardDoubleArrowLeftIcon style={{position:'relative',top:'5px'}}/>
                jumpNextIcon=<KeyboardDoubleArrowRightIcon style={{position:'relative',top:'5px'}}/>
                className="pagination" 
                total={pagination.total} 
                current={pageNumber}
                onChange = {(current,pageSize)=>{ changePage(current) }}
                />
        </>
    )
}

export default DecisionList