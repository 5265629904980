import React, { useContext, useEffect, useState } from 'react'
import './Header.css'
import { Grid } from '@mui/joy'
import Dropdown from '@mui/joy/Dropdown'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import AppsIcon from '@mui/icons-material/Apps';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
//import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom'
import axios from 'axios'

const Header = ({user,setUser}) => {

 // const dispatch = useDispatch();
  const[language,setLanguage] = useState(user.language);

  const { t,i18n } = useTranslation();

  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

  const logoutUser = () => {
    localStorage.removeItem('user');
    setUser({
      email:"",
      pasword:"",
      isAuth:false,
    });
  }

  useEffect(()=>{
    console.log(user,'USER');
    axios.post('/change-language',{id:user.id,language}).then((res)=>{
      if(res.data.message=='success'){
        if(localStorage.user){
          let localStorageUser = JSON.parse(localStorage.user);
          localStorageUser.language = language;
          localStorage.setItem('user',JSON.stringify(localStorageUser));

        }
        i18n.changeLanguage(language);
      }
    })
  },[language,localStorage.user])
 

  

  return (
    <div className='header'>
      <Grid container sx={{ flexGrow: 1 }}>
          <Grid xs={4} md={7} lg={9}>
          <h3 style={{margin:'30px 20px'}}>{t('title')}</h3>
          </Grid>
          <Grid xs={8} md={5} lg={3}>
            <Dropdown>
             <MenuButton className="profile-button" style={{margin:'10px',color:'#fff'}} variant='plain' >
              <PersonIcon style={{backgroundColor:'#3c9800',borderRadius:'3px'}} />
             </MenuButton>
              <Menu>
                <MenuItem><PersonIcon></PersonIcon>{user.full_name}</MenuItem>
                <MenuItem><Link to="/user" style={{color:'#000',textDecoration:'none'}}>Profile</Link></MenuItem>
                <MenuItem onClick={()=>logoutUser()}>Logout</MenuItem>
              </Menu>
            </Dropdown>
            <Dropdown>
             <MenuButton className="language-button" style={{margin:'10px',color:'#fff'}} variant='plain' startDecorator={<LanguageIcon style={{backgroundColor:'#ff7000',borderRadius:'3px'}} />}>{language}</MenuButton>
              <Menu>
                <MenuItem onClick={()=>setLanguage('uz')}>Uz</MenuItem>
                <MenuItem onClick={()=>setLanguage('ru')}>Ru</MenuItem>
              </Menu>
            </Dropdown>
          </Grid>
         
          
        </Grid>
    </div>
  )
}

export default Header