import React, { useEffect, useState } from 'react';
import Info from '@mui/icons-material/Info';
import Delete from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Grid,Card,Typography,Table,Sheet } from '@mui/joy';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Chip from '@mui/joy/Chip';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import { typographyClasses } from '@mui/joy/Typography';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';

const  DecisionView = () => {

  const { id } =  useParams();
  const [history,setHistory] = useState(false);
  const [tree,setTree] = useState(false);
  const [model,setModel] = useState(false);  
  const[relatedModels,setRelatedModels] = useState([]);
  const [files,setFiles] = useState(false);  

  const { t,i18n } = useTranslation();
  

  const downloadFile = (fileId)=>{
    
    let link = document.createElement('a');
    link.download = 'hello.pdf';
    link.href = `http://127.0.0.1:8000/api/admin/decision/file/dowload/${fileId}`;
    link.click();

  }  


  const getNumber = (id) => {
    let number = null;
    relatedModels.map((item)=>{
        if(item.id == id){
            number = item.number;
        }
        if(model.id == id){
            number = model.number;
        }
    });
    return number;
  }

  useEffect(() => {
    
    axios.get(`/admin/decision/view/${id}`).then((response) => {
       
        if(response.data.main){
            setModel(response.data.main);
        }

        if(response.data.files){
            setFiles(response.data.files);
        }

        if(response.data.tree){
            setTree(response.data.tree);
        }

        if(response.data.model && response.data.model.length){
            
            let sorted = {};
            setRelatedModels(response.data.model);
            response.data.model.map((obj) => {
                sorted[obj.id] = obj;
            });    

            let v = Object.keys(response.data.tree).map((key) => {
                
                let variant="plain";
                let color = "neutral";
                if(sorted[key]){

                    if(response.data.tree[key].type){

                        variant = "soft";  

                        if(response.data.tree[key].type=="stop"){
                           color = "danger";
                        }

                        if(response.data.tree[key].type=="change"){
                            color = "warning";
                        }


                    }
                return <Card style={{maxWidth:'500px',margin:"0 0 20px 0"}} color={color} variant={variant}>
                    {
                        sorted[key] ? 
                            <>
                                <h2>Number #{sorted[key].number}</h2>
                                <p>{sorted[key].description}</p>
                                {
                                    (response.data.tree[key] && response.data.tree[key].child) ?
                                        response.data.tree[key].child.map((childKey) => {
                                            {
                                               return sorted[childKey] ? 
                                                    <b>{sorted[childKey].number}</b>
                                                :"";
                                            }
                                            
                                        })
                                    :""
                                }
                            </>
                            

                        :""
                    }
                    
                   


                </Card>
                }
            });

            setHistory(v);


            let step = Object.keys(response.data.tree).map((key) => {
                
                let variant="soft";
                let color = "neutral";
                let icon = <CheckRoundedIcon />

                 
             
                if(sorted[key]){

                    if(response.data.tree[key].type){

                        variant = "soft";  

                        if(response.data.tree[key].type=="cancel"){
                           color = "danger";
                           icon = <AutoDeleteIcon />
                        }

                        if(response.data.tree[key].type=="change"){
                            color = "warning";
                            icon = <SyncProblemIcon  />
                        }


                    }
                return <Card style={{maxWidth:'500px',margin:"0 0 20px 0"}} color={color} variant={variant}>
                  
                    <Stepper
                    orientation="vertical"
                    sx={{
                        '--Stepper-verticalGap': '2.5rem',
                        '--StepIndicator-size': '2.5rem',
                        '--Step-gap': '1rem',
                        '--Step-connectorInset': '0.5rem',
                        '--Step-connectorRadius': '1rem',
                        '--Step-connectorThickness': '4px',
                        '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                        [`& .${stepClasses.completed}`]: {
                        '&::after': { bgcolor: 'success.solidBg' },
                        },
                        [`& .${stepClasses.active}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            border: '4px solid',
                            borderColor: '#fff',
                            boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                        },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                        color: 'neutral.softDisabledColor',
                        },
                        [`& .${typographyClasses['title-sm']}`]: {
                        textTransform: 'uppercase',
                        letterSpacing: '1px',
                        fontSize: '10px',
                        },
                    }}
                >
                {
                    <>     
                        <Step
                            completed
                            indicator={
                            <StepIndicator variant="solid" color="primary">
                                <AccountTreeIcon  />
                            </StepIndicator>
                            }
                            className="decision-view-step" 
                        >
                            <div>
                            <Typography level="title-sm">{sorted[key].number}</Typography>
                            {sorted[key].description}
                            </div>
                        
                        </Step>
                 
                        {
                            
                       

                        (response.data.tree[key].child) ?
                            response.data.tree[key].child.map((childKey) => {
                                let current = childKey == model.id;
                                return <div className={current ? "current_model":""} >
                                        <Step
                                                completed
                                                indicator={
                                                    <StepIndicator  variant="solid" color={color}>
                                                        {icon}
                                                    </StepIndicator>
                                                    }
                                                className="decision-view-step"    
                                                >
                                            <div>
                                            <Typography level="title-sm">
                                                {sorted[key].number}  
                                                <Typography
                                                color="success"
                                                level="title-sm"
                                                variant="outlined"
                                                levelMapping="body-xs"
                                                >
                                                    {sorted[key].acceptance_date}
                                                </Typography>    
                                                
                                            </Typography>
                                            <Typography
                                                level="title-sm"
                                                >
                                                {sorted[key].description}
                                            </Typography>  
                                                <Chip
                                                    color="primary"
                                                    variant="solid"
                                                    onClick={() => {
                                                        // do something...
                                                    }}
                                                >
                                                More
                                                </Chip>
                                            </div>
                                        </Step>
                                    </div>
                            })
                        :""
                        }
                    </>
                }
                    </Stepper>
                </Card>
                }
               
            });
            setHistory(step);

        }

    });

  },[id]); 

  return (

    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={6}>
            <Card variant="outlined" sx={{ maxWidth: 500 }}>
            

                { (model) ?
                  <>
                        <Typography level="h2" fontSize="xl" sx={{ mb: 0.5 }}>
                            {model.number}
                        </Typography>
                        <Typography>
                           {model.description}
                        </Typography>  
                        <a href="#" id="download_file" style={{display:'none'}}></a> 
                        <ul>
                        {
                            files ?
                                files.map((file)=>{
                                    return <li onClick={()=>downloadFile(file.id)} className='decision-download-file'><AttachFileIcon style={{fontSize:'17px'}}/>{file.file_name}</li>
                                })              
                            
                            :""
                        }    
                        </ul>
                  </>  
                :""
                }

                
            </Card>
            <Card variant="outlined" sx={{ maxWidth: 500,marginTop:'30px' }}>
            <Sheet>
                    <Table
                        borderAxis="xBetween"
                        size="sm"
                        stripe="odd"
                        variant="solid"
                    >
                    <tbody>
                        <tr>
                            <td>Chastota</td>
                            <td>
                                {   
                                    (model.frequency) ?
                                        model.frequency.map((item) => {
                                            return `${item.frequency_start} - ${item.frequency_end} ${item.measure} / `;
                                        })
                                    :""
                                }
                             </td>
                        </tr>
                        <tr>
                            <td>Importer</td>
                            <td>
                            {
                                (model.importer) ? model.importer.name :""
                            }      
                            </td>
                        </tr>
                        <tr>
                            <td>Ishlab chiqaruvchi</td>
                            <td>
                            {
                                (model.developer) ? model.developer.name :""
                            }    
                            </td>
                        </tr>
                        <tr>
                            <td>Xudud</td>
                            <td>
                            {   
                                    (model.area) ?
                                        model.area.map((item) => {
                                            return <p>{item.title}</p>;
                                        })
                                    :""
                            }
                            </td>
                        </tr>
                        <tr>
                            <td>Technology</td>
                            <td>
                                {   
                                    (model.technology) ?
                                        model.technology.map((item) => {
                                            return item.title+" / ";
                                        })
                                    :""
                                }
                            </td>
                        </tr>
                    </tbody>
                    </Table>
                </Sheet>
            </Card>
            
        </Grid>
        <Grid xs={6}>
            <div className='tree-view'>
                {
                   Object.keys(tree).map((key) => {
                                let color = tree[key].status == 'cancel' ? 'danger' : tree[key].status == 'change' ? 'warning' : 'success'; 
                                let status = t(`status.${tree[key].status}`);
                               return <Card style={{margin:"10px"}}><Stack spacing={2} style={{margin:'10px 0'}} sx={{ width: '100%' }}>
                                    <Stepper size="md" orientation="vertical">
                                        <Step
                                        indicator={
                                            <StepIndicator variant="solid" color="primary">
                                                <AssignmentLateIcon sx={{ fontSize: 15 }}  />
                                            </StepIndicator>
                                        }
                                        >
                                        <Link className='link-without-underline' to={`/decision/view/${tree[key].main}`}>#{getNumber(tree[key].main)}</Link>
                                        <Typography level="body-sm">
                                            {tree[key].definition}
                                        </Typography>
                                        </Step>
                                        {
                                        (tree[key].child && tree[key].child.length) ?
                                            tree[key].child.map((child) => {
                                                return (
                                                    <Step indicator={<StepIndicator variant="solid" color={color}><AssignmentLateIcon sx={{ fontSize: 15 }} /></StepIndicator>}>
                                                        <Link className='link-without-underline' to={`/decision/view/${child}`}>{getNumber(child)}</Link>
                                                        <Typography style={{margin:'0 3px'}} color={color} noWrap>
                                                            [{status}]
                                                        </Typography>
                                                    </Step>
                                                )
                                            })
                                        :""
                                        }
                                    
                                    </Stepper>
                                    </Stack>
                                </Card>    
                      
                    })
                }
                {
                    /*
                        tree ?  
                            tree.map((item) => {
                                let color = item.status == 'cancel' ? 'danger' : item.status == 'change' ? 'warning' : 'success'; 
                                let status = t(`status.${item.status}`);
                               return <Stack spacing={2} style={{margin:'10px 0'}} sx={{ width: '100%' }}>
                                    <Stepper size="md">
                                        <Step
                                        indicator={
                                            <StepIndicator variant="solid" color="primary">
                                                <AssignmentLateIcon sx={{ fontSize: 15 }}  />
                                            </StepIndicator>
                                        }
                                        >
                                        <Link to={`/decision/view/${getNumber(item.main)}`}>{getNumber(item.main)}</Link>
                                        </Step>
                                        <Typography style={{margin:'0 3px'}} variant="outlined" color={color} noWrap>
                                            {status}
                                        </Typography>
                                        
                                        {
                                            
                                        (item.child && item.child.length) ?
                                            item.child.map((child) => {
                                                return <Step indicator={<StepIndicator variant="solid" color={color}><AssignmentLateIcon sx={{ fontSize: 15 }} /></StepIndicator>}><Link to={`/decision/view/${getNumber(child)}`}>{getNumber(child)}</Link></Step>
                                            })
                                        :""
                                        }
                                    
                                    </Stepper>
                                    </Stack>
                            })
                         :"34234"
                    */
                }
                {history}
            </div>    
        </Grid>
    </Grid>

  )
}

export default DecisionView