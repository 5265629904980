import React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BluetoothAudioIcon from '@mui/icons-material/BluetoothAudio';
import CategoryIcon from '@mui/icons-material/Category';
import Box from '@mui/joy/Box';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <Box
        sx={{
        width: '100%',
        maxWidth: 800,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 2,
        }}
    >
        <Card variant="solid" color="danger" invertedColors>
            <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                    <StickyNote2Icon />
                </CircularProgress>
                <CardContent>
                    <Typography level="h4">Qarorlar</Typography>
                </CardContent>
            </CardContent>
            <CardActions>
                <Link to="/admin/decision/adm">
                    <Button variant="soft" size="sm">
                        Taxrirlash
                    </Button>
                </Link>
            </CardActions>
        </Card>
        <Card variant="solid" color="danger" invertedColors>
            <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                    <CategoryIcon />
                </CircularProgress>
                <CardContent>
                <Typography level="h4">Qaror turi</Typography>
                </CardContent>
            </CardContent>
            <CardActions>
                <Link to="/admin/decision/type">
                    <Button variant="soft" size="sm">
                        Taxrirlash
                    </Button>
                </Link>
            </CardActions>
        </Card>
        <Card variant="solid" color="danger" invertedColors>
            <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                    <LocationCityIcon />
                </CircularProgress>
                <CardContent>
                <Typography level="h4">Hudud</Typography>
                </CardContent>
            </CardContent>
            <CardActions>
                <Link to="/admin/decision/area">
                    <Button variant="soft" size="sm">
                        Taxrirlash
                    </Button>
                </Link>
            </CardActions>
        </Card>
        <Card variant="solid" color="danger" invertedColors>
            <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                    <AccountBalanceIcon />
                </CircularProgress>
                <CardContent>
                    <Typography level="h4">Tashkilot</Typography>
                </CardContent>
            </CardContent>
            <CardActions>
                <Link to="/admin/decision/company">
                    <Button variant="soft" size="sm">
                        Taxrirlash
                    </Button>
                </Link> 
            </CardActions>
        </Card>
        <Card variant="solid" color="danger" invertedColors>
            <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                    <BluetoothAudioIcon />
                </CircularProgress>
                <CardContent>
                <Typography level="h4">Texnologiya</Typography>
                </CardContent>
            </CardContent>
            <CardActions>
                <Link to="/admin/decision/technology">
                    <Button variant="soft" size="sm">
                        Taxrirlash
                    </Button>
                </Link>
            </CardActions>
        </Card>
        <Card variant="solid" color="danger" invertedColors>
            <CardContent orientation="horizontal">
                <CircularProgress size="lg" determinate value={20}>
                    <StickyNote2Icon />
                </CircularProgress>
                <CardContent>
                    <Typography level="h4">Xizmat</Typography>
                </CardContent>
            </CardContent>
            <CardActions>
                <Link to="/admin/decision/service">
                    <Button variant="soft" size="sm">
                        Taxrirlash
                    </Button>
                </Link>
            </CardActions>
        </Card>
    </Box>
  )
}

export default Dashboard;