import { Button, Input } from '@mui/joy';
import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const  Profile = () => {
 
   
    const { t,i18n } = useTranslation();

    const [user,setUser] = useState({
        id:"",
        name:"",
        last_name:"",
    })
    let localStorageUser = false;
    useEffect(() => {
        if(localStorage.user){
            localStorageUser = JSON.parse(localStorage.user);
            setUser({
                id:localStorageUser.id,
                name:localStorageUser.name,
                last_name:localStorageUser.last_name,
            });
        }
    },[])


    const updateProfile = () => {
        
        axios.post(('/update-profile'),user).then((response) => {
            if(response.data.message == 'success'){
                
                localStorageUser = JSON.parse(localStorage.user);
                
                let userData = {
                    ...localStorageUser,
                    isAuth:true,
                    full_name:user.name+' '+ user.last_name,
                    name:user.name,
                    last_name:user.last_name,
                };

                localStorage.setItem('user',JSON.stringify(userData));
              
                Swal.fire({
                    title: t("Updated"),
                    text: t("Your profile has been deleted"),
                    icon: "success",
                    timer:1500,
                }).then(() => {
                    window.location.reload();
                });
                
            }


        });

    }
   


    return (
        <div>
            <p>
                <Input value={user.name} defaultValue={user.name} onChange={(e)=>setUser({...user,name:e.target.value})}/>
            </p>
            <p>
                <Input value={user.last_name} defaultValue={user.last_name} onChange={(e)=>setUser({...user,last_name:e.target.value})}/>
            </p>
            <Button onClick={()=>updateProfile()}>Save</Button>
        </div>
    )

}

export default Profile