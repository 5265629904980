import React, { useEffect, useState } from 'react';
import { Search as SearchIcon, Add as AddIcon,DeleteOutline as DeleteOutlineIcon ,Save as SaveIcon, DesignServices,} from '@mui/icons-material';
import { Table, Input, Grid, Button,Checkbox, Modal, ModalDialog, ModalClose, Card, CardContent, Box, MenuItem, Select,Option,IconButton, CardActions, FormControl, Textarea,ButtonGroup } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import Pagination from 'rc-pagination/lib/Pagination';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CachedIcon from '@mui/icons-material/Cached';
import Swal from 'sweetalert2';
import { Link, useSearchParams } from 'react-router-dom';
import DecisionUpdateForm from './DecisionUpdateForm';
import DecisionCreateForm from './DecisionCreateForm';
import { useForm } from 'react-hook-form';


const Decision = () => {
  
  const { t } = useTranslation();
  const [reload,setReload] = useState(false);
  const [dataList,setDataList] = useState([]);
  const [openModal,setOpenModal] = useState(false);
  

  const [searchParams,setSearchParams] = useSearchParams();


  const [dateString,setDateString] = useState({
    issue:"",
    deadline:"",
  });


  const [pagination,setPagination] = useState({
    total:0,
    rowsPerPage:0,
  });

  let [selected,setSelected] = useState([]);


  let defaultSearchForm = {

    number:"",
    name:"",
    area:"",
    page:1,

  }

const [searchForm,setSearchForm] = useState(defaultSearchForm)


  let currentPageNumber  = searchParams.get('page') ? parseInt(searchParams.get('page')):1;
    
  const[pageNumber,setPageNumber] = useState(currentPageNumber);

  const [defaultTypeList,setDefaultTypeList] = useState([]);


  const defaultActiveRecord = useState({
    acceptance_date:"",
    area:[],
    deadline:"", 
    decision_type_id:"",
    description:"",
    developer:"",
    frequency: [],
    id:"",
    import_company:"",
    importer:"", 
    made_company:"",
    number: "",
    status:"", 
    technology:[],
    type: {},
    updated_at:"",
    user: "",
    user_company : "", 
    
    /* */

    issue:"",
    fileList:[],
    related:[],
    relationDefination:"",
    service:"",
    relationStatus:"",
    relationDirection:true,

    /* */


  })

 const [activeRecord,setActiveRecord] = useState(defaultActiveRecord);


  const [decision,setDecision] = useState({

    number:'',
    type:'',
    issue:'',
    description:'',
    name:'',
    fileList:[],
    area:'',
    developer:'',
    importer:'',
    deadline:'',
    frequency:[],
    related:[],
    relationDefination:'',
    service:'',
    relationStatus:'',
    relationDirection:true,
    
});





  useEffect(() => {
      axios.get('/admin/decision/index').then((response) => {
        setDataList([...dataList,...response.data.data]);
        setPagination({rowsPerPage:response.data.per_page,total:response.data.total}); 
      });    



  },[]);

  useEffect(()=>{
    axios.get('/admin/decision/index?page='+pageNumber).then((response) => {
      setDataList(response.data.data);
      setPagination({rowsPerPage:response.data.per_page,total:response.data.total}); 
    }); 

    axios.get(`/admin/decision/type`).then((response) => {
      let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
      setDefaultTypeList(options);
    });
  
   
  },[pageNumber,reload]);

  

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();  
  


  const onSubmit = () => {

   // console.log(activeRecord,'After full');

    /*

    decision.number = getValues('decisionNumber');
    decision.type = getValues('decisionType').value;
    decision.issue = dateString.issue;
    decision.deadline = dateString.deadline;
   
    return axios.post('/admin/decision/create',activeRecord).then((res) => {
        if(res.message=='success'){
          setDecision({
            
            number:'',
            type:'',
            issue:'',
            description:'',
            name:'',
            fileList:[],
            area:'',
            developer:'',
            importer:'',
            deadline:'',
            frequency:[],
            related:[],
            relationDefination:'',
            service:'',
            relationStatus:'',
            relationDirection:true,

          })

          setValue('decisionNumber','');
          setValue('decisionType','');

        }
    });

    */

  };


  const changePage = (page) => {
    setSelected([]);
    setSearchParams({page:page})
    setPageNumber(page);
  }

 

  const getAreaList = (input) => {

    return axios.get(`/admin/decision/area/search?q=${input}`).then((response) => {
        let options = response.data.data.map((item) => ({ value:item.id, label: item.title }));
        return options;
    });

}



const search = () => {
 
  let link = `admin/decision/part-search?number=${searchForm.number}&area=${searchForm.area}&name=${searchForm.name}&page=${pageNumber}`;
    axios.get(link).then((res) => {
      
      if(!res.data.data.length && pageNumber > 1){
          let page = pageNumber-1;
          setPageNumber(page);
          setSearchParams({page:page});
      }

      setPagination({rowsPerPage:res.data.per_page,total:res.data.total}); 
      setDataList(res.data.data);

  });

}

const clearSearch = () => {
        setSearchForm(defaultSearchForm);
        setSearchParams({pageType:'default'});
        window.location.reload();
}



 

  const editSingleDecision = (item) => {
    /*
    console.log(item);
    setDecision(item);
    setValue('decisionNumber',item.number);
    setValue('decisionType',item.type)
    */
   console.log(defaultActiveRecord);
    setActiveRecord(item)
    setOpenModal(true);

  }


  const removeSelected = () => {
    
    Swal.fire({
        title: t("Are you sure")+"?",
        text: t("You won't be able to revert this")+"!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it")+"!",
    }).then((result) => {
        if (result.isConfirmed) {

            axios.post('admin/decision/remove-selected/',{payload:selected}).then((res) => {
                    if(res.data.message=='success'){
                        setReload(!reload);
                        setSelected([]);
                        setDataList([]);
                        Swal.fire({
                            title: t("Deleted"),
                            text: t("Your object has been deleted"),
                            icon: "success"
                        });
                    }
            });

        }
    });
}




const setRecordList = (item,action,all=false) => {
  let selectedData = [];
  console.log(all,'SELECT');
  if(all){
      selectedData =  dataList.map((el,index)=>{
          el.isChecked = action;
          if(action){
              if(!selected.includes(el.id)){
                  selected.push(el.id)
              }
          }else{
              selected.map((iden,index)=>{
                  if(iden==el.id){
                      selected.splice(index,1);
                  }
              })
          }
          return el;
      });
  }else{
      selectedData =  dataList.map((el) => {
          if(el.id==item.id){
              el.isChecked = action;
              if(action){
                  selected.push(el.id);
              }else{
                  selected.map((iden,index) => {
                      if(iden==el.id){
                          selected.splice(index,1);
                      }
                  })
              }
          }
          return el;
      });
  }
  
  setDataList(selectedData);

}


const removeRecord = (id) => {
    Swal.fire({
        title: t("Are you sure")+"?",
        text: t("You won't be able to revert this")+"!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Yes, delete it")+"!",
    }).then((result) => {
        if (result.isConfirmed) {

            axios.delete('admin/decision/remove/'+id).then((res) => {
                    if(res.data.message=='success'){
                        setReload(!reload);
                        Swal.fire({
                            title: t("Deleted"),
                            text: t("Your object has been deleted"),
                            icon: "success"
                        });
                    }
            });

        }
    });

}


  
  
  return (
      <div>
        
              <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
              <ModalDialog variant="soft" size="lg">
                  <p>
                      <ModalClose variant="plain" sx={{ m: 1 }} />   
                  </p>
                    
                      {/* */}

                      <Card style={{minHeight:'300px'}}>

                        <CardContent>
                          {
                            (activeRecord.id) ? 
                              <DecisionUpdateForm />
                            : <DecisionCreateForm />
                          }
                        </CardContent>
                        <CardActions>
                                                          
                            

                        </CardActions>        
                      </Card>

                      {/* */}



              </ModalDialog>
          </Modal>
        <p>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Input placeholder='Qaror Raqami' onChange={(e)=>setSearchForm({...searchForm,number:e.target.value})}/>
            </Grid>
          
            <Grid item xs={3}>
              <AsyncSelect placeholder='Xudud' className='async-select' onChange={(e)=>{setSearchForm({...searchForm,area:e.value})}}  loadOptions={getAreaList} />
            </Grid>
            <Grid item xs={2}>

            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button onClick={search} variant="contained" color="success" style={{backgroundColor:'#277A1F',color:'#fff'}}><SearchIcon/></Button>
            
              <Button variant="contained" onClick={clearSearch} color="success"  style={{backgroundColor:'#c41c1c',color:'#fff'}}><CachedIcon /></Button>
            
            </ButtonGroup>
    
            </Grid>
          </Grid>
        </p>
        <Table size="md" variant="outlined" borderAxis="both"  style={{borderRadius:'5px'}}>
        <thead style={{borderRadius:'5px'}}>
                  <tr>
                    <th style={{width:'20px'}}><Checkbox defaultChecked={false} onChange={(e)=> setRecordList({},e.target.checked,true)} className='checking-area' /></th>
                    <th>Qaror raqami</th>
                    <th>Nomi</th>
                    <th>Xudud</th>
                    <th>Chastota</th>
                    <th>
                    <Grid container spacing={1}>
                          <Grid item>
                            <Button onClick={()=>{setActiveRecord(defaultActiveRecord);setOpenModal(true)}} variant='solid' color="success"><AddIcon /></Button>
                          </Grid>
                          <Grid item>
                            {   
                              selected.length ?
                              <Button onClick={()=>removeSelected()} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                              :""
                            }
                          </Grid>
                    </Grid>      
                        
                        
                    </th>
                  </tr>
              </thead>
              <tbody>
                {
                  dataList.map((item) => {
                    return (
                      <tr>
                        <td><Checkbox value={item.isChecked} onChange={(e)=>setRecordList(item,e.target.checked)}  className='checking-area' /></td>
                        <td>{item.number}</td>
                        <td>{item.description}</td>
                        <td>{23}</td>
                        <td></td>
                        <td>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Button onClick={()=>editSingleDecision(item)} variant='solid' color="success"><EditIcon /></Button>
                          </Grid>
                          <Grid item>
                            <Button onClick={()=>removeRecord(item.id)} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                          </Grid>
                        </Grid>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
        </Table>
        <Pagination 
          prevIcon=<KeyboardArrowLeftIcon/>
          nextIcon=<KeyboardArrowRightIcon/>
          jumpPrevIcon=<KeyboardDoubleArrowLeftIcon style={{position:'relative',top:'5px'}}/>
          jumpNextIcon=<KeyboardDoubleArrowRightIcon style={{position:'relative',top:'5px'}}/>
          className="pagination" 
          total={pagination.total} 
          current={pageNumber}
          onChange = {(current,pageSize)=>{ changePage(current) }}
        />

      </div>
  );
}

export default Decision