import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/joy'
import { Report } from '@mui/icons-material'

const  Loading = () => {
  return (
    <div className="wrapper">
        <CircularProgress color="danger" sx={{ '--CircularProgress-size': '150px' }}>
            <Report color="danger" />
        </CircularProgress>
    </div>
  )
}


export default Loading
