import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sheet,Table,Button,Checkbox,Input, Stack,FormLabel, Typography, Textarea } from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import Pagination from 'rc-pagination/lib/Pagination';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Loading from '../../../Component/Loading';
import { useSearchParams } from 'react-router-dom';






const Technology = () => {

    const { t } = useTranslation();

    const[openModal,setOpenModal] = useState(false);
    const[reload,setReload] = useState(false);
    const [query,setQuery] = useState("");
    
    const[record,setRecord] = useState({
        id:0,
        title:"",
        description:"",
    })

    let [selected,setSelected] = useState([]);
    
    const [pagination,setPagination] = useState({
        total:0,
        rowsPerPage:0,
    });

  
    const search = () => {
        let link = 'admin/decision/technology/search?q='+query+'&page='+pageNumber;
        axios.get(link).then((res)=>{
            if(!res.data.data.length && pageNumber > 1){
                let page = pageNumber-1;
                setPageNumber(page);
                setSearchParams({page:page});
            }
            setPagination({rowsPerPage:res.data.per_page,total:res.data.total}); 
            let normalizedData = res.data.data.map((item)=>{
                return {
                    id:item.id,
                    title:item.title,
                    description:item.description,
                    isChecked:false,
                }
            })

            setData(normalizedData);
   
        });
    }

    const[data,setData] = useState([]);

    const [searchParams,setSearchParams] = useSearchParams();

    let currentPageNumber  = searchParams.get('page') ? parseInt(searchParams.get('page')):1;
    
    const[pageNumber,setPageNumber] = useState(currentPageNumber);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
      } = useForm();  
      
  
    
      useEffect(()=>{
        let link = 'admin/decision/technology?page='+pageNumber;
        axios.get(link).then((res) => {

            if(!res.data.data.length && pageNumber > 1){
                let page = pageNumber-1;
                setPageNumber(page);
                setSearchParams({page:page});
            }

            setPagination({rowsPerPage:res.data.per_page,total:res.data.total}); 

            let normalizedData = res.data.data.map((item)=>{
                return {
                    id:item.id,
                    title:item.title,
                    description:item.description,
                    isChecked:false,
                }
            })

            setData(normalizedData);  

        })
       
      },[pageNumber,reload]);
    



    const changePage = (page) => {
        setSelected([]);
        setSearchParams({page:page})
        setPageNumber(page);
    }

    const onSubmit = (data) => {
        if(record.id){

            axios.post('admin/decision/technology/update',record).then((response) => {
                
                setRecord({id:0,title:""});
                setOpenModal(false);
                
                Swal.fire({
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500
                });

                setReload(!reload);  

            })
        
        }else{
            axios.post('admin/decision/technology/create',record).then((response)=>{
                setRecord({id:0,title:""});
                setOpenModal(false);
                
                Swal.fire({
                    icon: "success",
                    title: "Your work has been saved",
                    showConfirmButton: false,
                    timer: 1500
                });
                setReload(!reload);  
            
            }).catch((error)=>{
                //console.log(error.response.code);
                setRecord({id:0,title:""});
                setOpenModal(false);
                Swal.fire({

                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    
                });
            })
        }
    };

    const updateRecord = (record) => {
        
        setRecord({
            id:record.id,
            title:record.title,
            description:record.description
        });

        setValue("company",record.title);
        setValue("company_desc", record.description);

        setOpenModal(true);
    }

    const addRecord = () => {
        setRecord({id:0,title:""});
        setValue("company","");
        setValue("company_desc","");
        setOpenModal(true);
    }

    const setRecordList = (item,action,all=false) => {
        let selectedData = [];
        if(all){
            selectedData =  data.map((el,index)=>{
                el.isChecked = action;
                if(action){
                    if(!selected.includes(el.id)){
                        selected.push(el.id)
                    }
                }else{
                    selected.map((iden,index)=>{
                        if(iden==el.id){
                            selected.splice(index,1);
                        }
                    })
                }
                return el;
            });
        }else{
            selectedData =  data.map((el)=>{
                if(el.id==item.id){
                    el.isChecked = action;
                    if(action){
                        selected.push(el.id);
                    }else{
                        selected.map((iden,index)=>{
                            if(iden==el.id){
                                selected.splice(index,1);
                            }
                        })
                    }
                }
                return el;
            });
        }
        setData(selectedData);
    }


    const removeSelected = () => {
      
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to revert this")+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it")+"!",
          }).then((result) => {
            if (result.isConfirmed) {

                axios.post('admin/decision/technology/remove-selected/',{payload:selected}).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            setSelected([]);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success"
                            });
                        }
                });

            }
          });
    }


    const removeRecord = (id) => {
        Swal.fire({
            title: t("Are you sure")+"?",
            text: t("You won't be able to revert this")+"!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Yes, delete it")+"!",
          }).then((result) => {
            if (result.isConfirmed) {

                axios.delete('admin/decision/technology/remove/'+id).then((res) => {
                        if(res.data.message=='success'){
                            setReload(!reload);
                            Swal.fire({
                                title: t("Deleted"),
                                text: t("Your object has been deleted"),
                                icon: "success"
                            });
                        }
                });

            }
          });
    
        }
  
           
   
   

    return (
    <Sheet>
        <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
            <ModalDialog variant="soft" size="lg">
                <p>
                    <ModalClose variant="plain" sx={{ m: 1 }} />   
                </p>
                    <form
                    onSubmit={handleSubmit(onSubmit)}
                    >
                        <Stack spacing={1}>
                            <FormLabel>*Korxona nomi {
                                
                                errors.company && errors.company.type=="required" &&
                                <Typography color="danger" level="body-xs" >
                                    The Field is required
                                </Typography>
                            }
                       
                            {       
                                errors.company && errors.company.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Input  {...register("company", { required: true,maxLength:30, })} value={record.title} variant="outlined" color="primary" onChange={(ev)=>setRecord({...record,title:ev.target.value})} />
                            <FormLabel>Korxona to'g'risida 
                         
                            {       
                                errors.company_desc && errors.company_desc.type=="maxLength" &&
                                <Typography color="danger" level="body-xs" >
                                    The max length is 30 charakter
                                </Typography>   
                            }
                            </FormLabel>
                            <Textarea color="primary" {...register("company_desc", { maxLength:300 })}  onChange={(ev)=>setRecord({...record,description:ev.target.value})} minRows={4} />
                            <Button type="submit">Submit</Button>
                        </Stack>
                    </form>
            </ModalDialog>
        </Modal>
        <p>
        <Input
                value={query}
                onChange={(e)=>setQuery(e.target.value)}
                startDecorator={<SearchIcon />}
                endDecorator={<><Button onClick={search}>{t('search')}</Button><Button color='danger' onClick={()=>{setQuery("");setReload(!reload)}}>clear</Button></>}
                variant="outlined" color="primary"
            />
        </p>
         
         <Table size="md" variant="outlined" borderAxis="both"  style={{borderRadius:'5px'}}>
            <thead style={{borderRadius:'5px'}}>
                <tr >
                <th style={{width:'20px'}}><Checkbox onChange={(e)=>setRecordList({},e.target.checked,true)} className='checking-area' /></th>
                <th>{t('company')}</th>
                <th>{t('description')}</th>
                <th style={{width:'300px'}}>
                    <Button onClick={()=>addRecord()} variant='solid' color="success"><AddIcon /></Button>
                    {   
                        selected.length ?
                        <Button onClick={()=>removeSelected()} variant='solid' color="danger"><DeleteOutlineIcon /></Button>
                        :""
                    }
                </th>
                </tr>
            </thead>


            <tbody>
                            
                 {
                    data.length ?
                    data.map((item) => {
                     
                        return (
                            <tr>
                            <td><Checkbox  onChange={(e)=>setRecordList(item,e.target.checked)} checked={item.isChecked} /></td>
                            <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td>
                                <Button size="md" onClick={()=>updateRecord(item)} startDecorator={<EditIcon />} variant='solid' color="primary">
                                {t('update')}
                                </Button>
                                <Button size="md" onClick={()=>removeRecord(item.id)}  startDecorator={<DeleteIcon />} variant="solid" color="danger">
                                {t('delete')}
                                </Button>
                                </td>
                            </tr>
                        )
                    })
                   : <Loading />
                 }           
               
            </tbody>
        </Table>
        <Pagination 

            prevIcon={<KeyboardDoubleArrowLeftIcon/>} 
            nextIcon={<KeyboardDoubleArrowRightIcon/>} 
            className="pagination" 
            total={pagination.total} 
            current={pageNumber}
            onChange = {(current,pageSize)=>{ changePage(current) }}

        />
    </Sheet>
  )
}

export default Technology